export const services = [
{
 key:"1",
 name:"Enterprise Technology Services",    
 path:"/services/ETS"    
},
{
 key:"2",
 name:"Business Process Services",
 path:"/services/BPS"
},
{
 key:"3",
 name:"Infrastructure Management Services",
 path:"/services/Infra"
},
{
 key:"4",
 name:"Cloud Management Services",
 path:"/services/Cloud"
},
{
 key:"5",
 name:"Staff Augmentation services",
 path:"/services/StaffAugmentation"
},
{
 key:"6",
 name:"ERP/SAP Services",
 path:"/services/ERP"
},
]
 
export const government = [
{
 key:"1",
 name:"Government Certificates",    
 path:"/governmentCertification"    
},
{
 key:"2",
 name:"Government Experiences",
 path:"/governmentExperience"
},
{
 key:"3",
 name:"Government Placments",
 path:"/governmentPlacement"
},

]
 
export const opportunity = [
{
 key:"1",
 name:"Current Job Openings",    
 path:"/jobOpening"    
},
{
 key:"2",
 name:"Talent Community",
 path:"/talentCommunity"
},
{
 key:"3",
 name:"Partners",
 path:"/partner"
},
]
 
export const inSight = [
{
 key:"1",
 name:"News",    
 path:"/news"
},
{
 key:"2",
 name:"Awards",
 path:"/awards"
}
]
 
export const help = [
    {
     key:"1",
     name:"Contact Us",    
     path:"/contact"    
    },
    {
     key:"2",
     name:"About",
     path:"/about"
    },
    {
     key:"3",
     name:"Testimonials",
     path:"/testimonial"
    },
    ]