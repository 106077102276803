import React from "react";
import Header from "../../Layouts/Header/Header";
import CommonSubBanner from "../../Components/CommonSubBanner/CommonSubBanner";
import "./style.css";

const DIRPage = () => {
  return (
    <>
      <Header page={"contracts"} />
      <CommonSubBanner
        className={"DirBG"}
        heading={"Texas DIR - ITSAC Contract"}
        text={
          "Explore our specialized IT staffing services under the Texas DIR ITSAC contract."
        }
      />

      <section className="containerDir">
        <div className="container">
          <div className="logo-wrapper">
            <img src="/assets/img/dirlogo.webp" alt="dir-logo" />
          </div>
          <h2>
            Texas Department of Information Resources (DIR) – IT Staff
            Augmentation (ITSAC) Contract
          </h2>
          <p>
            <strong>ACS Consultancy Services, Inc.</strong> is proud to be one
            of the selected vendors awarded the{" "}
            <strong>
              Information Technology Staff Augmentation Contract{" "}
              <a
                href="https://dir.texas.gov/cooperative-contracts/it-staffing-services"
                rel="noreferrer"
                target="_blank"
              >
                (ITSAC)
              </a>{" "}
            </strong>{" "}
            by the Texas Department of Information Resources (DIR) under{" "}
            <a
              href="https://dir.texas.gov/contracts/dir-cpo-5582"
              rel="noreferrer"
              target="_blank"
            >
              Contract No. DIR-CPO-5582 .
            </a>{" "}
            Through this contract, we provide specialized IT staffing services
            to Texas state agencies, K–12 public education institutions, cities,
            counties, other local governments, and public universities.
          </p>
          <p>
            DIR’s{" "}
            <a
              href="https://dir.texas.gov/cooperative-contracts"
              rel="noreferrer"
              target="_blank"
            >
              Cooperative Contracts Program
            </a>{" "}
            streamlines the procurement process, allowing state and local
            government entities to select qualified IT staff from a pre-approved
            list of vendors without the need for lengthy bid processes. Agencies
            can either conduct competitive solicitations or submit a Statement
            of Work (SOW) directly to a vendor. Additionally, services can be
            procured directly via the DIR IT Staffing Program webpage or by
            contacting <strong>ACS Consultancy Services, Inc.</strong> directly.
          </p>
          <div>
            <h3>
              Services Offered by{" "}
              <strong>ACS Consultancy Services, Inc.</strong>{" "}
            </h3>

            <p>
              Under the ITSAC contract,{" "}
              <strong>ACS Consultancy Services, Inc.</strong> offers
              comprehensive IT staffing augmentation services on a temporary,
              hourly basis across a wide range of IT disciplines, including:
            </p>
            <ul>
              <li>Applications / Software Development</li>
              <li>Data / Database Administration</li>
              <li>Web Development</li>
              <li>Quality Assurance & Testing</li>
              <li>Networking / Telecommunications</li>
              <li>Security</li>
              <li>Project Management</li>
              <li>Technical Services Help Desk & Operations</li>
              <li>
                Information Technology Service Management (ITSM Operations)
              </li>
              <li>IT Marketing</li>
              <li>Information Technology Contract Management</li>
            </ul>
            <p>
              For a detailed list of job categories and descriptions available
              through the contract, please refer to the{" "}
              <a
                target="_blank"
                href="/assets/pdf/DIR-CPO-5582 Appendix D ITSAC Job Category, Title Descriptions.pdf"
              >
                ITSAC Services Titles and Descriptions.
              </a>
            </p>
          </div>

          <div>
            <h3>Why Choose ACS Consultancy Services, Inc.</h3>
            <p>
              At <strong>ACS Consultancy Services, Inc.</strong>, we are
              dedicated to delivering top-quality resources for technical and
              executive staffing needs. Our team of highly skilled professionals
              is carefully vetted to ensure that our clients receive the best
              talent for their projects. With the ITSAC contract, public sector
              customers can easily purchase our services either through the{" "}
              <a target="_blank"  rel="noreferrer"  href="https://dir.texas.gov/View-Contracts-And-Services/Pages/Content.aspx?id=13">
                DIR IT Staffing Program
              </a>{" "}
              webpage or directly from{" "}
              <strong>ACS Consultancy Services, Inc.</strong>, without the need
              for any additional bidding or contract processing. It’s fast,
              efficient, and reliable.
            </p>
          </div>
          <div>
            <h3>How to Procure Our Services </h3>
            <p>
              To start the process of procuring IT staffing services through ACS
              Consultancy Services, Inc. under the ITSAC contract:
            </p>
            <ol>
              <li>
                Contact Us Directly: For service offerings and pricing
                information, get in touch with our team, and we’ll assist you in
                identifying the best resources for your needs. We offer
                competitive pricing based on DIR’s contracted rates or special
                negotiated rates, ensuring you receive the best value for your
                project.
              </li>
              <li>
                Place a Purchase Order: Once you're ready, simply issue a
                purchase order referencing DIR Contract No. DIR-CPO-5582.
              </li>
              <li>
                Submit Your Order: You may either:
                <ul>
                  <li>
                    Submit your purchase order directly via the <a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/View-Contracts-And-Services/Pages/Content.aspx?id=13">DIR IT Staffing
                    Program webpage</a> , or
                  </li>
                  <li>
                    Email your purchase order and quote request form to
                    DIR@acsincny.com, and our team will follow up promptly.
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div>
            <h3>Contact Information</h3>
            <p>
              <strong>Asha Ramrakhiani</strong>
              <br />
              ACS Consultancy Services Inc.
              <br />
              Phone: 518-221-5409
              <br />
              Email: <a href="mailto:DIR@acsincny.com">DIR@acsincny.com</a>
              <br />
              Vendor ID: 452054570
            </p>
            <p>
              To order services under the ITSAC contract, fill out and submit
              this form via the  <a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/View-Contracts-And-Services/Pages/Content.aspx?id=13">DIR IT Staffing Program webpage</a> , or email us
              directly at DIR@acsincny.com.
            </p>
          </div>
          <div>
            <h3>Helpful Links </h3>
            <ul>
              <li><a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/View-Contracts-And-Services/Pages/Content.aspx?id=13"> DIR IT Staffing Program </a></li>
              <li><a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/View-About-DIR/Pages/Content.aspx?id=41">DIR Cooperative Contracts Program Overview</a></li>
              <li><a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/">DIR About Page</a></li>
              <li><a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/View-Contracts-And-Services/Pages/Content.aspx?id=13">List of ITSAC Services Titles and Descriptions </a></li>
              <li><a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/View-Contracts-And-Services/Pages/Content.aspx?id=13">ITSAC Purchase Order Submission Form </a></li>
              <li>
                <strong>ACS Consultancy Services, Inc.</strong> DIR ITSAC
                Contract Number: <a target="_blank"  rel="noreferrer"  href="https://dir.texas.gov/contracts/dir-cpo-5582">DIR-CPO-5582</a> 
              </li>
            </ul>
          </div>
          <div>
            <h3>About DIR</h3>
            <p>
              The Texas Department of Information Resources (DIR) is committed
              to serving Texas government by leading the state’s technology
              strategy, protecting state technology infrastructure, and offering
              innovative and cost-effective solutions for all levels of
              government. Learn more about DIR’s mission at
              <a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/">https://www.dir.texas.gov.</a> 
            </p>
            <p>
              <strong>ACS Consultancy Services, Inc.</strong> DIR ITSAC Contract
              Number: <a target="_blank"  rel="noreferrer" href="https://dir.texas.gov/contracts/dir-cpo-5582">DIR-CPO-5582</a> 
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default DIRPage;
