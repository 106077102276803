// export const API_BASE_URL = "http://127.5000_api0.1:5000/api";

export const endPoint = {
    Auth_SignIn : `Auth/SignIn`,
    Auth_ChangePassword : `Auth/ChangePassword`,
    Auth_ResetPassword : `Auth/ResetPassword`,
    Job_AddJob : `Job/AddJob`,
    Job_GetAllJobs : `Job/GetAllJobs`,
    Job_GetJobDetailsById : `Job/GetJobDetailsById`,
    Job_ApplyNow : `Job/ApplyNow`,
    User_UploadFile : `User/UploadFile`,
}
 