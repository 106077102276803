import React from 'react'
import OtpInput from 'react18-input-otp';
import Button from '../../Components/Button/Button';
import { useNavigate } from 'react-router-dom';
const OtpSubmit = ({ formData, setFormData , handleFormData, submitHandler }) => {
   const navigate = useNavigate()
    const handleChange = (value) => {
        handleFormData({ name: "otp", value: value })
    }

     const clearOtpHandler = () => {
        setFormData({...formData , otp : "" })
     }
    return (
        <>

<div className="adminLoginBox">
          <div className="adminHeading">Please Enter OTP</div>
          <div className="container px-5">
                <div className='d-flex flex-column  justify-content-center align-items-center '>
                    <span className='my-2 text-white'>check your mail box OTP is sent to your mail</span>
                <OtpInput
                    value={formData.otp}
                    onChange={handleChange}
                    numInputs={4}
                    separator={<span className='text-white' >-</span>}
                    containerStyle={"d-flex justify-content-center  align-items-center my-3  "}
                    inputStyle={{
                         width:"60px",
                        color: "#ff364c",
                        margin: "5px",
                        border: "2px solid #ff364c",
                        fontSize: "32px"
                    }}
                />
                </div>

                <div className="d-flex flex-row justify-content-around align-items-center my-4">
                    <Button
                        className="HeroButton headerBtn"
                         onClick={clearOtpHandler}
                         name="Clear"
                    />
                 
                    <Button
                        className="HeroButton headerBtn"
                     onClick={(e) => submitHandler(e,"otp") }
                   name="Submit"
                   />
                       

                     
                </div>

                <div className="d-flex flex-wrap align-items-center justify-content-center">
                    <p className="text-white">
                        Back to  {" "} <span className="text-danger" onClick={() => navigate("/admin")}> Login
                        </span>
                    </p>
                  
                </div>
            </div>

  </div>


         
        </>
    )
}

export default OtpSubmit