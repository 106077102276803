import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
} from "../types.js";

const initialState = {
  adminInfo: undefined,
};
export const AdminLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
      return { loading: true };
    case ADMIN_LOGIN_SUCCESS:
      return { ...state, loading: false, adminInfo: action.payload };
    case ADMIN_LOGIN_FAIL:
      return { ...state, loading: false, adminInfo: action.payload };
    case ADMIN_LOGOUT:
      return { ...state, loading: false, adminInfo: undefined };
    default:
      return state;
  }
};

 
