import {
    ADMIN_CHANGE_PASSWORS_REQUEST,
    ADMIN_CHANGE_PASSWORS_SUCCESS,
    ADMIN_CHANGE_PASSWORS_FAIL,
    ADMIN_CHANGE_PASSWORS_RESPONSE,

    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAIL,
    FORGOT_OTP_REQUEST,
    FORGOT_OTP_SUCCESS,
    FORGOT_OTP_FAIL,
    CONFIRM_PASSWORD_REQUEST,
    CONFIRM_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_FAIL,
  } from "../types.js";
  
  const initialState = {
    changePassword: undefined,
  };
  export const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADMIN_CHANGE_PASSWORS_REQUEST:
        return { loading: true };
      case ADMIN_CHANGE_PASSWORS_SUCCESS:
        return { ...state, loading: false, changePassword: action.payload };
      case ADMIN_CHANGE_PASSWORS_FAIL:
        return { ...state, loading: false, changePassword: action.payload };
      case ADMIN_CHANGE_PASSWORS_RESPONSE:
        return { ...state, loading: false, changePassword: undefined };
      case FORGET_PASSWORD_REQUEST:
          return { loading: true };
      case FORGET_PASSWORD_SUCCESS:
          return { loading: false, forgotPassword: action.payload };
      case FORGET_PASSWORD_FAIL:
          return { loading: false, forgotPassword: action.payload };
      case FORGOT_OTP_REQUEST:
          return { loading: true };
      case FORGOT_OTP_SUCCESS:
           return { loading: false, forgotOtp: action.payload };
      case FORGOT_OTP_FAIL:
          return { loading: false, forgotOtp: action.payload };
      case CONFIRM_PASSWORD_REQUEST:
          return { loading: true };
      case CONFIRM_PASSWORD_SUCCESS:
          return { loading: false, confirmPassword: action.payload };
      case CONFIRM_PASSWORD_FAIL:
          return { loading: false, confirmPassword: action.payload };
      default:
        return state;
    }
  };
  
   
  