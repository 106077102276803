
import { jobsData } from "../../utils/jobs.js";
import { axiosInstance } from "../ApiInterceptor/Axios_Interceptors.js";
import { endPoint } from "../config/config.js";
import {
  ADMIN_JOBS_REQUEST,
  ADMIN_JOBS_SUCCESS,
  ADMIN_JOBS_FAIL,
  ADMIN_JOBS_RESPONSE,
  ADMIN_ADD_JOBS_REQUEST,
  ADMIN_ADD_JOBS_SUCCESS,
  ADMIN_ADD_JOBS_FAIL,
  ADMIN_ADD_JOBS_RESPONSE,
  GET_JOB_DETAIL_BY_ID_REQUEST,
  GET_JOB_DETAIL_BY_ID_SUCCESS,
  GET_JOB_DETAIL_BY_ID_FAIL,
  APPLY_JOB_REQUEST,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_FAIL,
  APPLY_JOB_RESPONSE,
} from "../types.js";


// admin jobs start

export const adminAddJobsAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ADD_JOBS_REQUEST,
    });
    const data = await axiosInstance.post(endPoint.Job_AddJob, formData);
      dispatch({
        type: ADMIN_ADD_JOBS_SUCCESS,
        payload: data.data,
      });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_JOBS_FAIL,
      payload: error?.data,
    });
  }
};

export const adminAddJobsResetResponse = () => ({
  type: ADMIN_ADD_JOBS_RESPONSE,
});
// admin jobs start

export const adminJobsAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_JOBS_REQUEST,
    });
    // const data = await axiosInstance.post(endPoint.Job_GetAllJobs, formData);
      dispatch({
        type: ADMIN_JOBS_SUCCESS,
        payload: jobsData,
      });
  } catch (error) {
    dispatch({
      type: ADMIN_JOBS_FAIL,
      payload: error?.data,
    });
  }
};

export const adminJobsResetResponse = () => ({
  type: ADMIN_JOBS_RESPONSE,
});

// admin jobs end

export const getJobDetailsByIdAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_DETAIL_BY_ID_REQUEST,
    });
    const data = await axiosInstance.post(endPoint.Job_GetJobDetailsById, formData);
      dispatch({
        type: GET_JOB_DETAIL_BY_ID_SUCCESS,
        payload: data.data,
      });
  } catch (error) {
    dispatch({
      type: GET_JOB_DETAIL_BY_ID_FAIL,
      payload: error?.data,
    });
  }
};

// apply job

export const applyJobAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: APPLY_JOB_REQUEST,
    });
    const data = await axiosInstance.post(endPoint.Job_ApplyNow, formData);
      dispatch({
        type: APPLY_JOB_SUCCESS,
        payload: data.data,
      });
  } catch (error) {
    dispatch({
      type: APPLY_JOB_FAIL,
      payload: error?.data,
    });
  }
};

export const applyJobResetResponse = () => ({
  type: APPLY_JOB_RESPONSE,
});
