import React from 'react'
import EmailSubmit from './EmailSubmit';
import NewPassword from './NewPassword';
import OtpSubmit from './OtpSubmit';

const Content = ({step , formData , setFormData  , handleFormData , submitHandler}) => {
    switch (step) {
      case 1:
        return <EmailSubmit  formData = {formData} handleFormData = {handleFormData} submitHandler={submitHandler} />;
      case 2:
        return <OtpSubmit  formData = {formData} setFormData={setFormData} handleFormData = {handleFormData} submitHandler={submitHandler} />
      case 3:
        return <NewPassword formData = {formData} handleFormData = {handleFormData} submitHandler={submitHandler} />
      default:
        return null;
    }
  };
export default Content

