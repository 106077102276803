import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Content from './Content';
import { confirmPasswordAction, forgotOtpAction, forgotPasswordAction } from '../../redux/actions/changePasswordAction';
import { toast } from 'react-toastify';

const Overview = ({ formData, setFormData, handleFormData }) => {
    const { forgotPassword, forgotOtp , confirmPassword  } = useSelector((state) => state.changePassword);
    
    const [step, setStep] = useState(1);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (forgotPassword?.statusCode === 200) {
          setStep(step + 1)
          toast.success(forgotPassword?.returnMessage[0])
        }
        if (forgotOtp?.statusCode === 200) {
          setStep(step + 1)
          toast.success(forgotOtp?.returnMessage[0])
          setFormData({ ...formData, token: forgotOtp?.data?.token })

        }
        if (confirmPassword?.statusCode === 200) {
          toast.success(confirmPassword?.returnMessage[0])
          navigate('/admin', { replace: true }) 
        } 
      }, [forgotPassword, forgotOtp , confirmPassword]);
    

    const upadatePassword = () => {
        if (formData.password === formData.confirmPassword) {
          if (formData.email && formData.otp && formData.password){
            dispatch(confirmPasswordAction({ email: formData.email , otp : formData.otp , password :  formData.password }))
          }
        } else {
          toast.warning("Password Doesn't Match")
        }
      }
    const submitHandler = (e, stepType) => {
        e.preventDefault();
        if (formData.email && stepType === "email" && step === 1) {
            dispatch(forgotPasswordAction({ email: formData.email }))
        }
        else if (formData.email && formData.otp && stepType === "otp" && step === 2) {
            dispatch(forgotOtpAction({ email: formData.email, otp: formData.otp }))
        }
        else if (stepType === "password" && formData.email && formData.otp && formData.password && step === 3) {
            upadatePassword()
        }
        }

  return (
    <>
    <div className="signIn_Form">
      <div className="forgot_bars_container my-4">
          <div className="forgot_bars_active" />
          <div
            className={step > 1 ? "forgot_bars_active" : "forgot_bars"}
          ></div>
          <div
            className={step > 2 ? "forgot_bars_active" : "forgot_bars"}
          ></div>
        </div>
        <Content step={step} formData={formData} setFormData={setFormData} handleFormData={handleFormData} submitHandler={submitHandler} />

      </div>
    </>
  )
}

export default Overview