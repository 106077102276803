import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./HeaderStyle.css";

const Header = ({ page }) => {
  // const location = useLocation();
  const [showMenu, setShowMenu] = useState();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const handleDropDown = id => {
    if (showMenu === id) {
      setShowMenu(null);
    } else {
      setShowMenu(id);
    }
  };

  return (
    <>
      <header>
        <div className="container-fluid">
          <nav className="navigationWrap">
            <Link to="/" className="logoAlign">
              <img src="/assets/svg/Logo.svg" height="45px" alt="" />
            </Link>
            <div
              className="acs-text-color fs-6"
              id="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <i class="fa fa-bars" aria-hidden="true"></i>
            </div>

            <div
              className={
                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
              }
            >
              <ul className="navigation">
                <li className={`navItem ${page == "home" ? "active" : ""}`}>
                  <Link to="/" className="navLink">
                    Home
                  </Link>
                </li>
                <li
                  className={`navItem ${page == "service" ? "active" : ""}`}
                  onClick={() => handleDropDown(1)}
                >
                  <a className="navLink">
                    Services{" "}
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.02409 6.49993L8.13016 6.606L8.23622 6.49993L14.2199 0.516209L15.7474 2.04366L8.13016 9.66091L0.512913 2.04366L2.04037 0.516209L8.02409 6.49993Z"
                        fill="white"
                        stroke="#0A2640"
                        stroke-width="0.3"
                      />
                    </svg>
                  </a>
                  <div className={`dropdown ${showMenu === 1 ? "active" : ""}`}>
                    <Link to="/services/ETS">
                      Enterprise Technology Services
                    </Link>
                    <Link to="/services/BPS">Business Process Services</Link>
                    <Link to="/services/Infra">
                      Infrastructure Management Services
                    </Link>
                    <Link to="/services/Cloud">Cloud Management Services</Link>
                    <Link to="/services/StaffAugmentation">
                      Staff Augmentation services
                    </Link>
                    <Link to="/services/ERP">ERP/SAP Services</Link>
                  </div>
                </li>
                <li
                  className={`navItem ${page == "contracts" ? "active" : ""}`}
                  onClick={() => handleDropDown(5)}
                >
                  <a className="navLink">
                    Contracts{" "}
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.02409 6.49993L8.13016 6.606L8.23622 6.49993L14.2199 0.516209L15.7474 2.04366L8.13016 9.66091L0.512913 2.04366L2.04037 0.516209L8.02409 6.49993Z"
                        fill="white"
                        stroke="#0A2640"
                        stroke-width="0.3"
                      />
                    </svg>
                  </a>
                  <div className={`dropdown ${showMenu === 5 ? "active" : ""}`}>
                    <Link to="/contracts/dir">DIR</Link>
                  </div>
                </li>
                <li
                  className={`navItem ${page == "gov" ? "active" : ""}`}
                  onClick={() => handleDropDown(2)}
                >
                  <a className="navLink">
                    Government{" "}
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.02409 6.49993L8.13016 6.606L8.23622 6.49993L14.2199 0.516209L15.7474 2.04366L8.13016 9.66091L0.512913 2.04366L2.04037 0.516209L8.02409 6.49993Z"
                        fill="white"
                        stroke="#0A2640"
                        stroke-width="0.3"
                      />
                    </svg>
                  </a>
                  <div className={`dropdown ${showMenu === 2 ? "active" : ""}`}>
                    <Link to="/governmentCertification">
                      Government Certificates
                    </Link>
                    <Link to="/governmentClients">Government Clients</Link>
                    <Link to="/governmentPlacement">Government Placements</Link>
                  </div>
                </li>
                <li
                  className={`navItem ${page == "job" ? "active" : ""}`}
                  onClick={() => handleDropDown(3)}
                >
                  <a className="navLink">
                    Opportunities{" "}
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.02409 6.49993L8.13016 6.606L8.23622 6.49993L14.2199 0.516209L15.7474 2.04366L8.13016 9.66091L0.512913 2.04366L2.04037 0.516209L8.02409 6.49993Z"
                        fill="white"
                        stroke="#0A2640"
                        stroke-width="0.3"
                      />
                    </svg>
                  </a>
                  <div className={`dropdown ${showMenu === 3 ? "active" : ""}`}>
                    <Link to="/jobOpening">Current Job Openings</Link>
                    <Link to="/talentCommunity">Talent Community</Link>
                    <Link to="/partner">Partners</Link>
                  </div>
                </li>
                <li className={`navItem ${page == "about" ? "active" : ""}`}>
                  <Link to="/about" className="navLink">
                    About Us
                  </Link>
                </li>
                <li
                  className={`navItem ${page == "insight" ? "active" : ""}`}
                  onClick={() => handleDropDown(4)}
                >
                  <a className="navLink">
                    Insight{" "}
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.02409 6.49993L8.13016 6.606L8.23622 6.49993L14.2199 0.516209L15.7474 2.04366L8.13016 9.66091L0.512913 2.04366L2.04037 0.516209L8.02409 6.49993Z"
                        fill="white"
                        stroke="#0A2640"
                        stroke-width="0.3"
                      />
                    </svg>
                  </a>
                  <div className={`dropdown ${showMenu === 4 ? "active" : ""}`}>
                    <Link to="/news">News</Link>
                    <Link to="/awards">Awards</Link>
                  </div>
                </li>
                <li className={`navItem ${page == "contact" ? "active" : ""}`}>
                  <Link to="/contact" className="navLink">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
