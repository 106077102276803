import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
 
import {
  AdminLoginReducer,
} from "./reducers/AdminLoginReducer";
import { adminJobsReducer } from "./reducers/jobsReducer";
import { changePasswordReducer } from "./reducers/changePassword";
import { uploadReducer } from "./reducers/UploadFile";
 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  adminLogin: AdminLoginReducer,
  adminJobs: adminJobsReducer,
  changePassword: changePasswordReducer,
  upload : uploadReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
