import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { API_JOB } from "../../../config";
import { AuthContext } from "../../../contexts/AuthContext";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import "./AddPopupStyle.css";
 
import { Country, State, City } from "country-state-city";
import { adminAddJobsAction, adminJobsAction } from "../../../redux/actions/jobsAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "antd";
import CustomModal from "../../CustumModal/CustomModal";

let onlyCountry = ["India" , "United States" , "Canada" ];
const AddJobPopup = ({ isActive, onCancel }) => {
  const job_types = ["IT", "Medical", "Non IT", "Other"];

  const defaultForm = {
    positionId:"",
    headline: "",
    jobType: "",
    qualification: "",
    requiredExp: "",
    description: "",
    email: "",
    email1: "",
    email2: "",
    country: "",
    city: "",
    state: "",
  };
  const { addJobs, loading } = useSelector((state) => state.adminJobs);
  const [formData, setFormData] = useState(defaultForm);
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const [qualification, setQualification] = useState("");
  const [jobType, setJobType] = useState(job_types[0]);
  const [jobCategory, setJobCategory] = useState("");
  const [desc, setDesc] = useState("");
  const [countryName, setCountryName] = useState({});
  const [stateName, setStateName] = useState({});
  const [cityName, setCityName] = useState({});
  const [required_experience, setRequired_experience] = useState("");
  const [coun, setCoun] = useState([]);
  const [sta, setSta] = useState([]);
  const [cit, setCit] = useState([]);
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useContext(AuthContext);

  const clearData = () => {
    setPosition("");
    setLocation("");
    setQualification("");
    setJobType("");
    setJobCategory("");
    setDesc("");
    setRequired_experience("");
  };

  const handleFormData = (e) => {
    const { name, type, value } = e.target;
    if (type === "select-one" && name !== "jobType") {
      let valueOut = value ? JSON.parse(value) : "";
      if (name == "country") setCountryName( value ? JSON.parse(value): "" ) ;
      if (name == "state") setStateName( value ? JSON.parse(value): "" ) ;
      if (name == "city") setCityName( value ? JSON.parse(value): "" ) ;
      setFormData({ ...formData, [name]: valueOut?.label });
    }else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    if (addJobs?.statusCode === 200) {
      onCancel();      
        dispatch(
          adminJobsAction({
            page: 1,
            pageSize: 10,
          })
        );    
    }
  }, [addJobs]);

  const onSave = async (e) => {
    e.preventDefault();

    let obj = {
      ...formData,
      email: [formData.email, formData.email1, formData.email2],
    };
    delete obj.email1;
    delete obj.email2;
    if (
      (obj.positionId === "" ||
      obj.headline === "" ||
      obj.jobType === "" ||
      obj.qualification === "" ||
      obj.requiredExp === "" ||
      obj.description === "" ||
      obj.email === [] ||
      obj.country === "" ||
      obj.city === "" ||
      obj.state === "")
    ) {
      toast.warn("All Fields are Required");
    } else {
      dispatch(adminAddJobsAction(obj));
    }
  };

  useEffect(() => {
    const countries = Country.getAllCountries();
    let updatedCountries = countries.map((country) => {
      return {
        label: country.name,
        value: country.isoCode,
        ...country,
      };
    })
 

    updatedCountries = updatedCountries.filter(item => onlyCountry.includes(item.name));
    setCoun(updatedCountries);
  }, []);

  useEffect(() => {
    if (countryName) {
      updatedStates(countryName.value);
    }
  }, [countryName]);

  useEffect(() => {
    if (stateName) updatedCities(countryName.value, stateName.value);
  }, [stateName]);

  const updatedStates = (countryId) => {
    let allState = State.getStatesOfCountry(countryId).map((state) => {
      return { label: state.name, value: state.isoCode, ...state };
    });
    setSta(allState);
  };

  const updatedCities = (countryId, stateId) => {
    let allCity = City.getCitiesOfState(countryId, stateId).map((city) => {
      return { label: city.name, value: city.isoCode, ...city };
    });
    setCit(allCity);
  };

  return (
    <>
      
      <CustomModal
        centered
        visible={isActive}
        // className={"bg-dark"}
        onSubmit={onSave}
        onCancel={onCancel}
        // onCancel={() => onClose(false)}

        loading={loading}
        width={800}
      >
        <form className="">
          <div className="popupBody">
            <div className="popupHeading" style={{ color: "" }}>
              Add Job
            </div>
            <Row gutter={16}>
              <Col className="gutter-row " span={12}>
            <Input
              className={"mt-40 form-control"}
              type={"text"}
              name="positionId"
              placeholder={"Position Id"}
              value={formData.positionId}
              onChange={handleFormData}
              required
            />
            </Col>
            
            <Col  className="gutter-row " span={12}>
            <Input
              className={"mt-40 form-control"}
              type={"text"}
              name="headline"
              placeholder={"Headline"}
              value={formData.headline}
              onChange={handleFormData}
              required
            />
            </Col>
            </Row>
            <Input
              className={"mt-20"}
              type={"text"}
              name="qualification"
              placeholder={"Qualification"}
              value={formData.qualification}
              onChange={handleFormData}
              required
            />
            <Row gutter={16}>
              <Col className="gutter-row" span={8}>
                <Input
                  className={"mt-20"}
                  type={"email"}
                  name="email"
                  placeholder={"E-mail"}
                  value={formData.email}
                  onChange={handleFormData}
                  required
                />
              </Col>
              <Col className="gutter-row" span={8}>
                <Input
                  className={"mt-20"}
                  type={"email"}
                  name="email1"
                  placeholder={"E-mail-1"}
                  value={formData.email1}
                  onChange={handleFormData}
                  required
                />
              </Col>
              <Col className="gutter-row" span={8}>
                <Input
                  className={"mt-20"}
                  type={"email"}
                  name="email2"
                  placeholder={"E-mail-2"}
                  value={formData.email2}
                  onChange={handleFormData}
                  required
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row " span={12}>
                <select
                className="form-select mt-20"
                  name="jobType"
                  value={formData.jobType}
                  onChange={handleFormData}
                  required
                >
                  <option value="">Select Job Type</option>
                  <option value="remote">Remote</option>
                  <option value="onSite">OnSite</option>
                  <option value="hybrid">Hybrid</option>
                </select>
              </Col>
              <Col className="gutter-row" span={12}>
                <select
                className="form-select mt-20"
                  name="country"
                  value={formData.country.label}
                  onChange={handleFormData}
                  required
                >
                  <option>Select Country</option>
                  {coun.length > 0 ? (
                    coun?.map((item, i) => (
                      <option key={i} value={JSON.stringify(item)}>
                        {item.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled> No Records Found</option>
                  )}
                </select>
              </Col>
              <Col className="gutter-row " span={12}>
                <select
                className="form-select mt-20"
                  placeholder="Job Type"
                  name="state"
                  value={formData.state.label}
                  onChange={handleFormData}
                  required
                >
                  <option>Select State</option>
                  {sta.length > 0 ? (
                    sta?.map((item, i) => (
                      <option key={i} value={JSON.stringify(item)}>
                        {item.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled > No Records Found</option>
                  )}
                </select>
              </Col>
              <Col className="gutter-row " span={12}>
                <select
                className="form-select mt-20"
                  placeholder="Job Type"
                  name="city"
                  value={formData.city.label}
                  onChange={handleFormData}
                  required
                >
                  <option>Select City</option>
                  {cit.length > 0 ? (
                    cit?.map((item, i) => (
                      <option key={i} value={JSON.stringify(item)}>
                        {item.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled> No Records Found</option>
                  )}
                </select>
              </Col>
            </Row>
            <textarea
              className="form-control mt-20"
              placeholder="Required Experience"
              name="requiredExp"
              id=""
              cols="10"
              rows="3"
              onChange={handleFormData}
              value={formData.requiredExp}
              required
            ></textarea>
            <textarea
              className="form-control mt-20"
              placeholder="Description"
              name="description"
              id=""
              cols="10"
              rows="3"
              onChange={handleFormData}
              value={formData.description}
              required
            ></textarea>

            {/* <div className="btnWrapBox mt-40">
              <Button
                className={"adminLoginBtn"}
                name={"Cancel"}
                onClick={onClose}
              />
              <Button
                type="submit"
                className={"HeroButton adminLoginBtn"}
                name={"Save"}
              />
            </div> */}
          </div>
        </form>
      </CustomModal>

      {/* </Modal> */}

      {/* 
    <div className={`popupWrap ${isActive && "active"}`}>
      <div
        className="popupContainer "
        style={{ overflowY: "scroll", height: "650px" }}
      >
        <form onSubmit={onSave}>
          <div className="popupBody">
            <div className="popupHeading">Add Awards</div>
            <Input
              className={"mt-40"}
              type={"text"}
              name="headline"
              placeholder={"Headline"}
              value={formData.headline}
              onChange={handleFormData}
              required
            />
            <Input
              className={"mt-20"}
              type={"text"}
              name="qualification"
              placeholder={"Qualification"}
              value={formData.qualification}
              onChange={handleFormData}
              required
            />
          
            <Input
              className={"mt-20"}
              type={"email"}
              name="email"
              placeholder={"E-mail"}
              value={formData.email}
              onChange={handleFormData}
              required
            />
            <Input
              className={"mt-20"}
              type={"email"}
              name="email1"
              placeholder={"E-mail-1"}
              value={formData.email1}
              onChange={handleFormData}
              required
            />
            <Input
              className={"mt-20"}
              type={"email"}
              name="email2"
              placeholder={"E-mail-2"}
              value={formData.email2}
              onChange={handleFormData}
              required
            />
            <div className="  ">
              <select
              className="form-select mt-20"
                name="jobType"
                value={formData.jobType}
                onChange={handleFormData}
                required
              >
                <option value="">Select Job Type</option>
                <option value="remote">Remote</option>
                <option value="onSite">OnSite</option>
                <option value="hybrid">Hybrid</option>
              </select>

              <select
              className="form-select mt-20"
                name="country"
                value={formData.country.label}
                onChange={handleFormData}
                required
              >
                <option>Select Country</option>
                {coun.length > 0 ? (
                  coun?.map((item, i) => (
                    <option key={i} value={JSON.stringify(item)}>
                      {item.label}
                    </option>
                  ))
                ) : (
                  <option> No Records Found</option>
                )}
              </select>

              <select
              className="form-select mt-20"
                placeholder="Job Type"
                name="state"
                value={formData.state.label}
                onChange={handleFormData}
                required
              >
                <option>Select State</option>
                {sta.length > 0 ? (
                  sta?.map((item, i) => (
                    <option key={i} value={JSON.stringify(item)}>
                      {item.label}
                    </option>
                  ))
                ) : (
                  <option> No Records Found</option>
                )}
              </select>

              <select
              className="form-select mt-20"
                placeholder="Job Type"
                name="city"
                value={formData.city.label}
                onChange={handleFormData}
                required
              >
                <option>Select City</option>
                {cit.length > 0 ? (
                  cit?.map((item, i) => (
                    <option key={i} value={JSON.stringify(item)}>
                      {item.label}
                    </option>
                  ))
                ) : (
                  <option> No Records Found</option>
                )}
              </select>
 
            </div>
            <textarea
              className="sameDesignTextArea mt-20"
              placeholder="Required Experience"
              name="requiredExp"
              id=""
              cols="10"
              rows="10"
              onChange={handleFormData}
              value={formData.requiredExp}
              required
            ></textarea>
            <textarea
              className="sameDesignTextArea mt-20"
              placeholder="Description"
              name="description"
              id=""
              cols="10"
              rows="10"
              onChange={handleFormData}
              value={formData.description}
              required
            ></textarea>

            <div className="btnWrapBox mt-40">
              <Button
                className={"adminLoginBtn"}
                name={"Cancel"}
                onClick={onClose}
              />
              <Button
                type="submit"
                className={"HeroButton adminLoginBtn"}
                name={"Save"}
              />
            </div>
          </div>
        </form>
      </div>
    </div> */}
    </>
  );
};

export default AddJobPopup;
