export const jobsData = {
    "errors": {},
    "data": [
        {
            "jobId": 155,
            "jobType": "onSite",
            "headline": "Network Engineer ",
            "positionId": "2807-RF-30-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com joe@acsincny.com"
        },
        {
            "jobId": 154,
            "jobType": "onSite",
            "headline": "Network Engineer ",
            "positionId": "2807-RF-30-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com joe@acsincny.com"
        },
        {
            "jobId": 153,
            "jobType": "hybrid",
            "headline": "Project Manager",
            "positionId": "2805-RF-30-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        },
        {
            "jobId": 152,
            "jobType": "hybrid",
            "headline": "Web Designer ",
            "positionId": "2802-RF-30-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        },
        {
            "jobId": 151,
            "jobType": "hybrid",
            "headline": "BA/Tester ",
            "positionId": "2798-RF-30-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        },
        {
            "jobId": 150,
            "jobType": "hybrid",
            "headline": "Cognos Developer ",
            "positionId": "2795-RFCD-24-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com joe@acsincny.com"
        },
        {
            "jobId": 149,
            "jobType": "hybrid",
            "headline": "Java/J2EE Developer",
            "positionId": "2797-RF-24-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        },
        {
            "jobId": 148,
            "jobType": "hybrid",
            "headline": "Business Analyst ",
            "positionId": "2794-RFCD-24-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        },
        {
            "jobId": 147,
            "jobType": "hybrid",
            "headline": "Lead Java developer",
            "positionId": "2792-RF-24-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        },
        {
            "jobId": 146,
            "jobType": "hybrid",
            "headline": "COBOL Analyst",
            "positionId": "2785-RF-30-40",
            "country": "United States",
            "city": "Albany",
            "state": "New York",
            "postedBy": "adminacs",
            "location": "New York,Albany",
            "assignee": "Apply@acsincny.com Applications@acsincny.com sam@acsincny.com"
        }
    ],
    "page": 1,
    "pageSize": 10,
    "total": 61,
    "returnStatus": true,
    "statusCode": 200,
    "returnMessage": []
}