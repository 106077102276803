import {
    UPLOAD_CV_REQUEST,
    UPLOAD_CV_SUCCESS,
    UPLOAD_CV_FAIL,
    UPLOAD_CV_RESPONSE,

  } from "../types.js";
  
  const initialState = {
    uploadCV: undefined,
    
  };
  export const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
    // upload file 
      case UPLOAD_CV_REQUEST:
        return { jobLoading: true };
      case UPLOAD_CV_SUCCESS:
        return { ...state, jobLoading: false, uploadCV: action.payload };
      case UPLOAD_CV_FAIL:
        return { ...state, jobLoading: false, uploadCV: action.payload };
      case UPLOAD_CV_RESPONSE:
        return { ...state, jobLoading: false, uploadCV : undefined };
      default:
        return state;
    }
  };
  