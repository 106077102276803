import React from 'react'
import {Spin} from 'antd';
// import "./Loading.scss"
import { LoadingOutlined } from '@ant-design/icons';
const Loading = ({className, mainClass, style, position, center}) => {

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 30,
          }}
          spin
        />
      );

  return (
    <div className='d-flex justify-content-center align-items-center' >
      <Spin className='d-flex justify-content-center align-items-center' />
    </div>
  )
};

export default Loading
 
 