import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import Textarea from "../../Components/Textarea/Textarea";
import { API_GET_IN_TOUCH } from "../../config";
import "./FooterStyle.css";
import FooterContent from "./FooterContent";

const Footer = () => {
  const [msgSent, setMsgSent] = useState(false);

  const defaultGetInTouch = {
    name: "",
    email: "",
    phone: "",
    query: "",
    message: "",
    comment:""
  };

  const [getInTouch, setGetInTouch] = useState(defaultGetInTouch);

  const onSave = (e) => {
    e.preventDefault();
    axios
      .post(API_GET_IN_TOUCH, getInTouch, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setMsgSent(true);
        setGetInTouch(defaultGetInTouch);
      })
      .catch((err) => {
        setMsgSent(false);
        setGetInTouch(defaultGetInTouch);
        console.log(err);
      });
  };

  const location = useLocation();

  return (
    <footer>
      <div
        className={`container ${
          location.pathname === "/contact" ? "contact " : ""
        }`}
      >

        <div className="topLevelFooter flexBox">
          <div className="topLevelLeft">
            <h2 className="footerHeading">Get in Touch</h2>
            <p className="footerText mt-10">
              We welcome your feedback, comments, and questions. Our dedicated
              office staff is always available to assist you with any inquiries
              you may have. Contact us today to learn more about how we can help
              you succeed.
            </p>

            <div className="listBox mt-30">
              <svg
                width="46"
                height="47"
                viewBox="0 0 46 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1.20996"
                  width="45.0002"
                  height="44.9997"
                  rx="22.4999"
                  stroke="white"
                />
                <path
                  d="M29.9368 27.3808V30.2264C29.9369 30.4301 29.8597 30.6263 29.7208 30.7754C29.5819 30.9244 29.3916 31.0152 29.1884 31.0295C28.8367 31.0536 28.5494 31.0665 28.3273 31.0665C21.2156 31.0665 15.4512 25.3021 15.4512 18.1906C15.4512 17.9684 15.4632 17.6811 15.4882 17.3295C15.5025 17.1262 15.5933 16.936 15.7423 16.7971C15.8914 16.6582 16.0876 16.581 16.2913 16.5811H19.137C19.2368 16.581 19.3331 16.618 19.4071 16.6849C19.4812 16.7518 19.5277 16.8439 19.5377 16.9432C19.5562 17.1283 19.5731 17.2756 19.5892 17.3874C19.7492 18.5035 20.0769 19.5891 20.5614 20.6072C20.6378 20.7682 20.5879 20.9605 20.4431 21.0635L18.7064 22.3044C19.7683 24.7786 21.74 26.7503 24.2142 27.8121L25.4535 26.0787C25.5042 26.0079 25.5781 25.9571 25.6623 25.9352C25.7466 25.9133 25.8359 25.9216 25.9146 25.9588C26.9327 26.4423 28.0179 26.7693 29.1336 26.9285C29.2455 26.9446 29.3928 26.9623 29.5763 26.98C29.6754 26.9902 29.7673 27.0368 29.8341 27.1108C29.9008 27.1849 29.9369 27.2811 29.9368 27.3808Z"
                  fill="white"
                />
              </svg>
              <span>+1 518-221-5409</span>
            </div>

            {/* <div className="listBox mt-20">
              <svg
                width="46"
                height="47"
                viewBox="0 0 46 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1.20947"
                  width="45"
                  height="45.0001"
                  rx="22.5"
                  stroke="white"
                />
                <g clip-path="url(#clip0_12_4234)">
                  <path
                    d="M13.8504 15.0688H31.4076C31.6663 15.0688 31.9144 15.1716 32.0973 15.3545C32.2802 15.5375 32.383 15.7856 32.383 16.0442V31.6506C32.383 31.9093 32.2802 32.1574 32.0973 32.3404C31.9144 32.5233 31.6663 32.626 31.4076 32.626H13.8504C13.5917 32.626 13.3436 32.5233 13.1607 32.3404C12.9778 32.1574 12.875 31.9093 12.875 31.6506V16.0442C12.875 15.7856 12.9778 15.5375 13.1607 15.3545C13.3436 15.1716 13.5917 15.0688 13.8504 15.0688ZM22.6875 23.5382L16.4333 18.2272L15.1701 19.7137L22.7002 26.1074L30.0947 19.7088L28.8189 18.233L22.6875 23.5382Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_12_4234">
                    <rect
                      width="23.4096"
                      height="23.4096"
                      fill="white"
                      transform="translate(10.9248 12.1431)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>Queryonline@acsincny.com</span>
            </div> */}

            <div className="listBox mt-20">
              <svg
                width="46"
                height="47"
                viewBox="0 0 46 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="1.20996"
                  width="45"
                  height="45"
                  rx="22.5"
                  stroke="white"
                />
                <g clip-path="url(#clip0_12_4245)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.6064 20.7555C15.6064 16.9807 18.6594 13.9277 22.4342 13.9277C26.209 13.9277 29.262 16.9807 29.262 20.7555C29.262 24.8229 24.9508 30.4315 23.1853 32.5676C22.7951 33.0358 22.0831 33.0358 21.6929 32.5676C19.9177 30.4315 15.6064 24.8229 15.6064 20.7555ZM19.9957 20.7555C19.9957 22.1016 21.0882 23.194 22.4342 23.194C23.7803 23.194 24.8727 22.1016 24.8727 20.7555C24.8727 19.4095 23.7803 18.317 22.4342 18.317C21.0882 18.317 19.9957 19.4095 19.9957 20.7555Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_12_4245">
                    <rect
                      width="23.4096"
                      height="23.4096"
                      fill="white"
                      transform="translate(10.9248 12.144)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>952 Troy Schenectady Rd, Suite #106 Latham, NY -12110</span>
            </div>
          </div>

          {location.pathname !== "/contact" ? (
            <div className="topLevelRight">
              <form onSubmit={onSave} className="whiteForm">
                <Input
                  className={"mt-20"}
                  type={"text"}
                  placeholder={"Name"}
                  value={getInTouch.name}
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, name: e.target.value })
                  }
                />
                <Input
                  className={"mt-20"}
                  type={"email"}
                  placeholder={"Email Address"}
                  value={getInTouch.email}
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, email: e.target.value })
                  }
                />
                <Input
                  className={"mt-20"}
                  type={"text"}
                  placeholder={"Phone Number"}
                  value={getInTouch.phone}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      /^[0-9\b]+$/.test(e.target.value)
                    ) {
                      setGetInTouch({ ...getInTouch, phone: e.target.value });
                    }
                  }}
                />
                  <textarea
                  className="sameDesignTextArea mt-20"
                  placeholder="Comment"
                  name=""
                  id=""
                  cols="10"
                  rows="10"
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, message: e.target.value })
                  }
                  value={getInTouch.comment}
                ></textarea>
                <Button className={"HeroButton mt-20"} name={"Submit"} />
                {msgSent == true && <div className="msgSent">Message Sent</div>}
              </form>
            </div>
          ) : (
            <div className="topLevelRight">
              <form onSubmit={onSave} className="whiteForm">
                <Input
                  className={"mt-20"}
                  type={"text"}
                  placeholder={"Name"}
                  value={getInTouch.name}
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, name: e.target.value })
                  }
                />
                <Input
                  className={"mt-20"}
                  type={"email"}
                  placeholder={"Email Address"}
                  value={getInTouch.email}
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, email: e.target.value })
                  }
                />
                <Input
                  className={"mt-20"}
                  type={"text"}
                  placeholder={"Phone Number"}
                  value={getInTouch.phone}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      /^[0-9\b]+$/.test(e.target.value)
                    ) {
                      setGetInTouch({ ...getInTouch, phone: e.target.value });
                    }
                  }}
                />
                <Input
                  className={"mt-20"}
                  type={"text"}
                  placeholder={"Query"}
                  value={getInTouch.query}
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, query: e.target.value })
                  }
                />
                <textarea
                  className="sameDesignTextArea mt-20"
                  placeholder="Description"
                  name=""
                  id=""
                  cols="10"
                  rows="10"
                  onChange={(e) =>
                    setGetInTouch({ ...getInTouch, message: e.target.value })
                  }
                  value={getInTouch.message}
                ></textarea>
                <Button className={"HeroButton mt-20"} name={"Submit"} />
                {msgSent == true && <div className="msgSent">Message Sent</div>}
              </form>
            </div>
          )}
        </div>

        <FooterContent />
      </div>
    </footer>
  );
};

export default Footer;
