// export const API_BASE_URL = "http://127.0.0.1:5000/api";
export const API_BASE_URL = "http://3.142.251.165:3646/api/v1.0";
export const API_LOGIN_URL = `${API_BASE_URL}/Auth/SignIn`;
export const API_LOGOUT_URL = `${API_BASE_URL}/logout`;

export const API_GOVERNMENT_EXPERIENCE_URL = `${API_BASE_URL}/governmentExperiences`;
export const API_JOB = `${API_BASE_URL}/jobs`;
export const API_GET_IN_TOUCH = `${API_BASE_URL}/getintouch`;
export const API_APPLICANTS = `${API_BASE_URL}/applicants`;
export const API_TALENT = `${API_BASE_URL}/talentCommunity`;
export const API_PARTNER = `${API_BASE_URL}/partnersForm`;
