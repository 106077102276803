import React, { useContext, useState } from 'react'
import CommonSubBanner from '../../Components/CommonSubBanner/CommonSubBanner';
import './CareerStyle.css';
import ServiceBox from '../../Components/serviceBox/ServiceBox';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import { AuthContext } from '../../contexts/AuthContext';
import { API_PARTNER } from '../../config';
import axios from 'axios';
import Header from '../../Layouts/Header/Header';

const Partners = () => {

    const partners = [
        'Sub Contractor',
        'Joint Venture',
        'Prime Contractor',
    ]

    const defaultPartner = {
        name: "",
        website: "",
        contactName: "",
        designation: "",
        phone_number: "",
        email: "",
        expertise: "",
        explain: "",
        address: "",
        state: "",
        city: "",
        zipcode: "",
        message: "",
    }


    const [partner, setPartner] = useState(defaultPartner);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onSave = async (e) => {

        e.preventDefault();
        const res = await axios.post(API_PARTNER, partner);

        if (res.status === 200) {
            setPartner(defaultPartner);
            setIsSubmitted(true);
        }
        else {
            setPartner(defaultPartner);
            setIsSubmitted(false);
        }
    }



    return (
        <>
            <Header page={'job'} />
            <CommonSubBanner currentPage={'Opportunities'} activePage={'Partners'} className={'partnerBG'} heading={'Partners'} text={'You can join us as paartners by filling the form.'} />
            <section className="gradientBG partnerWrapper">
                <div className="container">

                    <h2 className="heading-3">Benefit from best-in-class capabilities, brought to you by ACS and the most trusted names in technology.</h2>

                    <div className="row mt-60">
                        <div className="col-lg-4 col-md-6 col-sm 12 mb-3" >
                        <ServiceBox src={'./assets/img/settings.png'} text={'Seamless integration keeps it simple'} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm 12 mb-3" >
                        <ServiceBox src={'./assets/img/glow.png'} text={'Big ideas call for big thinking'} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm 12 mb-3" >
                        <ServiceBox src={'./assets/img/workingUser.png'} text={'The latest in emerging technology'} />
                        </div>
                    </div>

                    <section className="multiLevelFormWrap">
                        <form onSubmit={onSave} className='whiteForm'>
                            <h3 className="heading-4">Let’s connect to find out what the power of our partnerships can do for you.</h3>
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Company Name'} value={partner.name} onChange={(e) => setPartner({ ...partner, name: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Company Website'} value={partner.website} onChange={(e) => setPartner({ ...partner, website: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Contact Name'} value={partner.contactName} onChange={(e) => setPartner({ ...partner, contactName: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Contact Person Designation'} value={partner.designation} onChange={(e) => setPartner({ ...partner, designation: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Phone Number'} value={partner.phone_number} onChange={(e) => { if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) { setPartner({ ...partner, phone_number: e.target.value }) } }} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Email'} value={partner.email} onChange={(e) => setPartner({ ...partner, email: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Domain Expertise'} value={partner.expertise} onChange={(e) => setPartner({ ...partner, expertise: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Explain'} value={partner.explain} onChange={(e) => setPartner({ ...partner, explain: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'Address'} value={partner.address} onChange={(e) => setPartner({ ...partner, address: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'State'} value={partner.state} onChange={(e) => setPartner({ ...partner, state: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'City'} value={partner.city} onChange={(e) => setPartner({ ...partner, city: e.target.value })} />
                            <Input required={true} className={'talentForm'} type={'text'} placeholder={'ZipCode'} value={partner.zipcode} onChange={(e) => setPartner({ ...partner, zipcode: e.target.value })} />
                            
                            <textarea className='sameDesignTextArea mt-20' placeholder='Message' name="" id="" cols="10" rows="10" value={partner.message} onChange={(e) => setPartner({ ...partner, message: e.target.value })}></textarea>

                            <div className="btnWraperCenter">
                                <Button type="submit" className={'HeroButton mt-20'} name={'Submit'} />
                            </div>
                        </form>
                        {
                            isSubmitted && <div className="successMsg">
                                <h3 className="heading-4">Thank you for your interest in partnering with us. We will get back to you shortly.</h3>
                            </div>
                        }
                    </section>

                </div>
            </section>
        </>
    )
}

export default Partners