import { Input } from "antd";
import React from "react";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";

const EmailSubmit = ({ formData, handleFormData, submitHandler }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="adminLoginBox">
        <div className="adminHeading">Forgot Password</div>
        <form onSubmit={(e) => submitHandler(e, "email")}>
          <Input
            size="large"
            className="mt-40"
            type="text"
            name="email"
            value={formData.email}
            onChange={handleFormData}
            placeholder="Email"
          />
          <Button
            type="submit"
            className={"HeroButton adminLoginBtn mt-40"}
            name={"Submit"}
          />
          <div className="d-flex flex-wrap align-items-center justify-content-center my-3">
            <p className="text-white">
              Back to{" "}
              <span className="text-danger" onClick={() => navigate("/admin")}>
                {" "}
                Login
              </span>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmailSubmit;
