import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { help, government, inSight, opportunity, services } from "../../_nav";

const FooterContent = () => {
  const navigate = useNavigate();
  const location =  useLocation();

const navigateHandler = (path) => {
    navigate(path)
}

  return (
    <>
      <div className="bottomLevelFooter flexBox">
        {/* <!--footer starts from here--> */}
        <div className="bottom_border w-100">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <h5 className="headin5_amrc col_white_amrc pt2 text-white">
                Services
              </h5>
              {/* <!--headin5_amrc--> */}

              <ul className="footer_ul_amrc mt-3">
                {services.map((item, i) => {
                    return(
                      <>
                        <p
                          onClick={() => navigateHandler(item.path)}
                          className={`${ location.pathname === item.path ? "text-danger" : "text-secondary"} my-2 fs-6`}
                        >
                         {item.name}
                        </p>
                      </>
                    )})}
              </ul>
            </div>
            <div className="col-lg col-md-4 col-sm-12">
              <h5 className="headin5_amrc col_white_amrc pt2 text-white">
                GOVERNMENT
              </h5>
              {/* <!--headin5_amrc--> */}
              <ul className="footer_ul_amrc mt-3">
              <ul className="footer_ul_amrc mt-3">
                {
                  government.map((item, i) => {
                    return(
                      <>
                        <p
                          onClick={() => navigateHandler(item.path)}
                          className={`${ location.pathname === item.path ? "text-danger" : "text-secondary"} my-2 fs-6`}
                        >
                         {item.name}
                        </p>
                      </>
                    )
                  } )
                }
              </ul>
              </ul>
              {/* <!--footer_ul_amrc ends here--> */}
            </div>
            <div className="col-lg col-md-4 col-sm-12">
              <h5 className="headin5_amrc col_white_amrc pt2 text-white">
                OPPORTUNITY
              </h5>
              {/* <!--headin5_amrc--> */}
              <ul className="footer_ul_amrc mt-3">
              <ul className="footer_ul_amrc mt-3">
                {
                  opportunity.map((item, i) => {
                    return(
                      <>
                        <p
                          onClick={() => navigateHandler(item.path)}
                          className={`${ location.pathname === item.path ? "text-danger" : "text-secondary"} my-2 fs-6`}
                        >
                         {item.name}
                        </p>
                      </>
                    )
                  } )
                }
              </ul>
              </ul>
              {/* <!--footer_ul_amrc ends here--> */}
            </div>   
            <div className="col-lg col-md-4 col-sm-12">
              <h5 className="headin5_amrc col_white_amrc pt2 text-white">
                INSIGHT{" "}
              </h5>
              {/* <!--headin5_amrc ends here--> */}
              <ul className="footer_ul_amrc mt-3">
              {
                  inSight.map((item, i) => {
                    return(
                      <>
                        <p
                          onClick={() => navigateHandler(item.path)}
                          className={`${ location.pathname === item.path ? "text-danger" : "text-secondary"} my-2 fs-6`}
                        >
                         {item.name}
                        </p>
                      </>
                    )
                  } )
                }
              </ul>
              {/* <!--footer_ul2_amrc ends here--> */}
            </div>
            <div className="col-lg col-md-4 col-sm-12">
            <h5  onClick={() => navigateHandler("/contact")} className="headin5_amrc col_white_amrc pt2 text-white">
                    CONTACT
                  </h5>
              {/* <!--headin5_amrc ends here--> */}

              <ul className="footer_ul_amrc mt-3">
              {
                help.map((item, i) => {
                    return(
                      <>
                        <p
                          onClick={ item.path !== "/testimonial" ? () => navigateHandler(item.path) : null }
                          className={`${ location.pathname === item.path ? "text-danger" : "text-secondary"} my-2 fs-6`}
                        >
                         {item.name}
                        </p>
                      </>
                    )
                  } )
                }
              
              </ul>
              {/* <!--footer_ul2_amrc ends here--> */}
            </div>
          </div>
        </div>
      </div>

      <div className="footerSocial flexBox">
        <p className="footerText footerText2">
          © 2022 ACS Consultancy Services. All Rights Reserved.
        </p>
        <div className="socialIcons">
          <svg
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.984863"
              width="45"
              height="45.0001"
              rx="22.5"
              stroke="white"
            />
            <path
              d="M32 18.2606C31.3386 18.5618 30.6265 18.7655 29.8796 18.8565C30.6424 18.3884 31.2277 17.6463 31.5034 16.7622C30.7892 17.1956 30 17.5109 29.1569 17.6799C28.4849 16.9442 27.5246 16.4849 26.4617 16.4849C24.4237 16.4849 22.7702 18.1804 22.7702 20.2714C22.7702 20.5683 22.8019 20.8565 22.8653 21.1338C19.7961 20.9757 17.0755 19.4697 15.253 17.1761C14.935 17.7373 14.7533 18.3884 14.7533 19.0818C14.7533 20.3949 15.4052 21.5542 16.3962 22.2335C15.7919 22.2151 15.2213 22.0428 14.7227 21.7611V21.8077C14.7227 23.643 15.9958 25.1739 17.6862 25.5206C17.3767 25.6095 17.0502 25.6539 16.7132 25.6539C16.4754 25.6539 16.243 25.6311 16.018 25.5867C16.4881 27.0905 17.851 28.1858 19.4675 28.2151C18.2039 29.2313 16.6107 29.837 14.8811 29.837C14.5832 29.837 14.2884 29.8196 14 29.785C15.6344 30.8576 17.5763 31.4849 19.6609 31.4849C26.4543 31.4849 30.168 25.7156 30.168 20.7113C30.168 20.5466 30.1648 20.3819 30.1585 20.2205C30.8801 19.6864 31.5066 19.0201 32 18.2606Z"
              fill="white"
            />
          </svg>

          <svg
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.984863"
              width="45.0002"
              height="44.9997"
              rx="22.4999"
              stroke="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.1188 19.2561H16.0934C14.8234 19.2561 14 18.4252 14 17.372C14 16.2983 14.8477 15.4849 16.1431 15.4849C17.4374 15.4849 18.2333 16.2963 18.2587 17.369C18.2587 18.4222 17.4374 19.2561 16.1188 19.2561ZM32.0001 31.4839H28.0218V25.8593C28.0218 24.3871 27.3995 23.382 26.0311 23.382C24.9844 23.382 24.4024 24.0602 24.1314 24.7139C24.042 24.9205 24.0436 25.1985 24.0452 25.4851C24.0455 25.524 24.0457 25.5631 24.0457 25.6021V31.4839H20.1045C20.1045 31.4839 20.1553 21.5204 20.1045 20.6148H24.0457V22.3206C24.2785 21.5747 25.5379 20.5103 27.5477 20.5103C30.0411 20.5103 32.0001 22.0737 32.0001 25.4403V31.4839ZM17.9624 20.6157H14.454V31.4849H17.9624V20.6157Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default FooterContent;
