import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CommonSubBanner from '../../Components/CommonSubBanner/CommonSubBanner'
import { API_GOVERNMENT_EXPERIENCE_URL } from '../../config';
import Header from '../../Layouts/Header/Header';
import './GovernmentServiceStyle.css';
import data from "../.."
const GovernmentExperience = () => {

    const [governmentExperience, setGovernmentExperience] = useState([
        {url :'/assets/img/patern1.png'},
        {url :'/assets/img/patern2.png'},
        {url :'/assets/img/patern3.png'},
        {url :'/assets/img/patern4.png'},
        {url :'/assets/img/patern5.png'},
        {url :'/assets/img/patern6.png'},
        {url :'/assets/img/patern7.png'},
        {url :'/assets/img/patern8.png'},
        {url :'/assets/img/patern9.png'},
        {url :'/assets/img/patern10.png'},
        {url :'/assets/img/patern11.png'},
        {url :'/assets/img/patern12.png'},
        {url :'/assets/img/patern13.png'},
        {url :'/assets/img/patern14.png'},
        {url :'/assets/img/patern15.png'},
        {url :'/assets/img/patern16.png'},
        {url :'/assets/img/patern17.png'},
        {url :'/assets/img/patern18.png'},
    ])


    // const getGovernmentExperience = async () => {
    //     const res = await axios.get(API_GOVERNMENT_EXPERIENCE_URL);
    //     setGovernmentExperience(res.data.gov_exp);
    // }

    // useEffect(() => {
    //     getGovernmentExperience();
    // }, [])

    return (
        <>
            <Header page={'gov'} />
            <CommonSubBanner currentPage={'Government'} activePage={'Government Clients '} className={'governmentBanner'} heading={'Government Clients '} text={'All certification we have in our showcase are below.'} />
            <section className="gradientBG governmentWrapper">
                <div className="container">

                    <div className="x5Wrapper flexBox mt-40">
          
                        {
                            governmentExperience.map((item, index) => {
                                return (
                                    <div className="boxBusiness" key={index}>
                                        <img src={item.url} alt="" />
                                        {/* <div className="text-3">{item.caption}</div> */}
                                    </div>
                                )
                            })
                        }

                    </div>

                </div>
            </section>
        </>
    )
}

export default GovernmentExperience