
import { axiosInstance } from "../ApiInterceptor/Axios_Interceptors.js";
import { endPoint } from "../config/config.js";
import {
  ADMIN_CHANGE_PASSWORS_REQUEST,
  ADMIN_CHANGE_PASSWORS_SUCCESS,
  ADMIN_CHANGE_PASSWORS_FAIL,
  ADMIN_CHANGE_PASSWORS_RESPONSE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  FORGOT_OTP_REQUEST,
  FORGOT_OTP_SUCCESS,
  FORGOT_OTP_FAIL,
  CONFIRM_PASSWORD_REQUEST,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAIL,
} from "../types.js";


// admin jobs start

export const changePasswordAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type:   ADMIN_CHANGE_PASSWORS_REQUEST,
    });
    const data = await axiosInstance.post(endPoint.Auth_ChangePassword, formData);
      dispatch({
        type:   ADMIN_CHANGE_PASSWORS_SUCCESS,
        payload: data.data,
      });
  } catch (error) {
     console.log("error", error.data);
    dispatch({
      type:   ADMIN_CHANGE_PASSWORS_FAIL,
      payload: error?.data,
    });
  }
};

export const changePasswordResetResponse = () => ({
  type:   ADMIN_CHANGE_PASSWORS_RESPONSE,
});



// reset {Password}

export const forgotPasswordAction = (mail) => async (dispatch) => {
  try {
      dispatch({
          type: FORGET_PASSWORD_REQUEST,
      });

      const { data } = await axiosInstance.post(
          endPoint.Auth_ResetPassword,
          mail
      );
      dispatch({
          type: FORGET_PASSWORD_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: FORGET_PASSWORD_FAIL,
          payload: error,
      });
  }
};

export const forgotOtpAction = (fromData) => async (dispatch) => {
  try {
      dispatch({
          type: FORGOT_OTP_REQUEST,
      });

      const { data } = await axiosInstance.post(endPoint.Auth_ResetPassword,
          fromData
      );
      dispatch({
          type: FORGOT_OTP_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: FORGOT_OTP_FAIL,
          payload: error,
      });
  }
};

export const confirmPasswordAction = (fromData) => async (dispatch) => {
  try {
      dispatch({
          type: CONFIRM_PASSWORD_REQUEST,
      });

      const { data } = await axiosInstance.post(endPoint.Auth_ResetPassword, fromData);
      dispatch({
          type: CONFIRM_PASSWORD_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: CONFIRM_PASSWORD_FAIL,
          payload: error,
      });
  }
};
