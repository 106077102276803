import React, { useState } from "react";
import { Form, Input, Space } from "antd";
import Button from "../../Components/Button/Button";

const NewPassword = ({ formData, handleFormData, submitHandler }) => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <div className="adminLoginBox">
        <div className="adminHeading">Enter New Password</div>
        <Form className="">
          <Input
            className={"mt-40"}
            size="large"
            placeholder="Current Password "
            name="email"
            readOnly
            value={formData.email}
            onChange={handleFormData}
          />
          <Input.Password
            className={"mt-20"}
            size="large"
            placeholder="New Password"
            name="password"
            value={formData.password}
            onChange={handleFormData}
          />
          <Input.Password
            className={"mt-20"}
            size="large"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleFormData}
          />

          <div className="d-flex justify-content-center mt-40">
            <Button
              type="submit"
              onClick={(e) => submitHandler(e, "password")}
              className={"HeroButton headerBtn "}
              name={"Submit"}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default NewPassword;
