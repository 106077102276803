import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../Components/Button/Button";
import CommonSubBanner from "../../Components/CommonSubBanner/CommonSubBanner";
// import Input from "../../Components/Input/Input";
import { API_APPLICANTS, API_JOB } from "../../config";
import Header from "../../Layouts/Header/Header";
import "./CareerStyle.css";
import { useDispatch, useSelector } from "react-redux";
import {
  applyJobAction,
  getJobDetailsByIdAction,
} from "../../redux/actions/jobsAction";
import { Input } from "antd";
import { toast } from "react-toastify";
import { uploadAction } from "../../redux/actions/UploadFile";
import { endPoint } from "../../redux/config/config";

const GetJob = () => {
  let imageType = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const { jobDetailById, applyJob } = useSelector((state) => state.adminJobs);
  const job = jobDetailById?.data;
  const defaultApplicant = {
    jobId: 0,
    fullName: "",
    email: "",
    phoneNumber: "",
    cv: "",
    coverLetter: "",
  };

  let { id } = useParams();
  //   const [job, setJob] = useState({});
  const [formData, setFormData] = useState(defaultApplicant);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const applyForJob = () => {
      const applyNow = (res) => {
      if(res.statusCode === 200){     
        dispatch(applyJobAction({...formData , jobId: id , cv : res.data.url}))
      }
      }
      const uploadImage = (value) => {
        const formData = new FormData();
        formData.append('File', value);
      
        fetch(`${process.env.REACT_APP_BASE_URL}${endPoint.User_UploadFile}`,
            {
              method: 'POST',
              body: formData,
            }
          )
            .then((response) => response.json())
            .then((res) => {
            applyNow(res)
            })
            .catch((error) => {
              toast.warn("something wrong in your uploading file")
              console.error('Error:', error);
            });
      }

     if(id){
      uploadImage(formData.cv)
     }
  }

  useEffect(() => {
    if (id) {
      dispatch(
        getJobDetailsByIdAction({
          jobId: id,
        })
      );
    }
  }, [id]);

  useEffect(() => {
     if(applyJob){
      if (applyJob?.statusCode != 200) {
        toast.warn( applyJob?.returnMessage[0] || "Something went wrong");
      }else{
        toast.success("applied successfully");
      }
     }
   
  }, [applyJob]);

  const onSave = async (e) => {
    e.preventDefault();
    if (
      formData.fullName === "" ||
      formData.email === "" ||
      formData.phoneNumber === "" ||
      formData.cv === "" ||
      formData.coverLetter === ""
    ) {
      toast.warn("Please Fill All the fields");
    } else {
       applyForJob()
    }
  };

  return (
    <>
      <Header page={"job"} />
      <CommonSubBanner
        currentPage={"Opportunities > Current Job Openings"}
        activePage={"Peoplesoft Functional Supply Chain Manager"}
        className={"careerBanner"}
      />
      <section className="gradientBG">
        <div className="container">
          <div className="flexBox formDetailWrap">
            <div className="formLeft">
              <h3 className="heading-3">
                <h6>{job?.position}</h6>
              </h3>
              <p className="text-3">
                <h6>{job?.description}</h6>
              </p>
              <p className="text-3 mt-20">Required Experience:</p>
              <p className="text-3">
                {" "}
                <h6>{job?.requiredExp}</h6>
              </p>
              <p className="text-3 mt-20">
                Qualification Required: <h6>{job?.qualification}</h6>
              </p>
              <p className="text-3 mt-20">
                Job Location: <h6>{job?.location} </h6>{" "}
              </p>
              <p className="text-3 mt-20">
                Job Category:<h6> {job?.headline} </h6>{" "}
              </p>
              <p className="text-3 mt-20">
                Job Type:<h6> {job?.jobType} </h6>
              </p>
            </div>
            <div className="formRight">
              <form onSubmit={onSave} className="whiteForm contactForm">
                <h3 className="formHeading">Apply Here</h3>
                <Input
                  size="large"
                  name="fullName"
                  required={true}
                  className={"mt-30"}
                  type={"text"}
                  placeholder={"Name"}
                  value={formData.fullName}
                  onChange={handleFormData}
                />
                <Input
                  size="large"
                  name="email"
                  required={true}
                  className={"mt-20"}
                  type={"email"}
                  placeholder={"Email Address"}
                  value={formData.email}
                  onChange={handleFormData}
                />
                <Input
                  size="large"
                  name="phoneNumber"
                  required={true}
                  className={"mt-20"}
                  maxlength="10"
                  type="tel"
                  placeholder={"Phone Number"}
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      /^[0-9\b]+$/.test(e.target.value)
                    ) {
                      return handleFormData(e);
                    }
                  }}
                />
                <Input
                  name="coverLetter"
                  size="large"
                  required={true}
                  className={"mt-20"}
                  type={"text"}
                  placeholder={"Comment"}
                  value={formData.coverLetter}
                  onChange={handleFormData}
                />
                <Input
                  size="large"
                  name="cv"
                  required={true}
                  className={"mt-20"}
                  type={"file"}
                  placeholder={"Upload CV"}
                  //   value={formData.cv}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      handleFormData({
                        target: {
                          name: e.target.name,
                          value: e.target.files[0],
                        },
                      });
                    }
                  }}
                  accept={
                    "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  }
                />
                <Button className={"HeroButton mt-20"} name={"Submit"} />
              </form>
              {isSubmitted && (
                <div className="alert alert-success mt-20" role="alert">
                  Application Submitted Successfully
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetJob;
