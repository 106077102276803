import { axiosInstance } from "../ApiInterceptor/Axios_Interceptors.js";
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
} from "../types.js";
import { endPoint } from "../config/config.js";

// user login

export const AdminLoginAction = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    });
    const data = await axiosInstance.post(endPoint.Auth_SignIn, formData);
      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: data.data,
      });
      localStorage.setItem("userData", JSON.stringify(data.data.data));
      localStorage.setItem("token", data.data.data.access_token);
 
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload: error?.data,
    });
  }
};


 
export const adminLoginResetResponse = () => ({
  type: ADMIN_LOGOUT,
});
