
// Find userType
export const userType = () => {
    const token = localStorage?.userData;
    let parsing = token ? JSON.parse(localStorage?.userData) : null;
    let usertype = parsing?.userType || null;
    return usertype;
  };
  
// checking isSignIn
  export const isSignIn = () => {
    if (localStorage.getItem("token")) {
      return true;
    }
    return false;
  };