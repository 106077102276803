import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import AdminHeader from "../../Layouts/AdminHeader/AdminHeader";
import { Input } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import Overview from "./Overview";
import { useDispatch } from "react-redux";
import "./ForgotPassword.css"
const ForgotPassword = () => {
  let defaultFormData = {
    email: "",
    password: "",
    otp: "",
    confirmPassword: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  
  const handleFormData = (e) => {
    if (e.name === "otp") {
      setFormData({ ...formData, [e.name]: e.value });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  
  const handleLogin = () => {
    console.log("handleLogin");
  };
  return (
    <>
      <LoadingOverlay
        //   active={loading}
        spinner
        // text='Loading your content...'
      >
        <AdminHeader />
        <section className="adminLoginWrap">
          <Overview
            formData={formData}
            setFormData={setFormData}
            handleFormData={handleFormData}
          />

        {/* <div className="text-center my-4" >
          <h6 className="text-white" onClick={()=>navigate("/admin")} style={{cursor : "pointer" }}>Back to Login</h6>
          </div> */}
        </section>
      </LoadingOverlay>
    </>
  );
};

export default ForgotPassword;
