import { placeholder } from "@babel/types";
import React from "react";
import "./InputStyle.css";

const Input = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  className,
  accept,
  required,
}) => {
  return (
    <input
      className={`${className} form-control`}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      accept={accept}
      required
    />
  );
};

export default Input;
