import React, { useEffect, useState } from "react";

import { EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import CustomModal from "../../CustumModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction, changePasswordResetResponse } from "../../../redux/actions/changePasswordAction";
import { toast } from "react-toastify";

const ChangePasswordModal = ({ isActive, onCancel }) => {
  const { changePassword } = useSelector((state) => state.changePassword);
  const defaultForm = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formData, setFormData] = useState(defaultForm);

  const handleFormData = (e) => {
    const { name, type, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (changePassword?.statusCode === 200) {
      toast.success(changePassword?.returnMessage[0]);
      dispatch(changePasswordResetResponse())
      onCancel();
      setFormData({
        ...formData,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      toast.warn(changePassword?.returnMessage[0]);
      dispatch(changePasswordResetResponse())
    }
  }, [changePassword]);

  const onSave = () => {
    if (
      formData.currentPassword === "" ||
      formData.newPassword === "" ||
      formData.confirmPassword === ""
    ) {
      toast.warn("All fields are required");
    } else {
      if (formData.newPassword === formData.confirmPassword) {
        dispatch(
          changePasswordAction({
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
          })
        );
      } else {
        toast.warn("Confirm password is not match");
      }
    }
  };

  return (
    <>
      <CustomModal
        centered
        visible={isActive}
        classBtn="headerBtn"
        // className={"headerBtn"}
        onSubmit={onSave}
        onCancel={onCancel}
        // loading={loading}

        width={500}
      >
        <Form className="">
          <div className="popupBody">
            <div className="popupHeading" style={{ color: "" }}>
              Change Password
            </div>

            <Input.Password
              className={"mt-40"}
              size="large"
              placeholder="Current Password "
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleFormData}
            />
            <Input.Password
              className={"mt-20"}
              size="large"
              placeholder="New Password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleFormData}
            />
            <Input.Password
              className={"mt-20"}
              size="large"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleFormData}
            />
          </div>
        </Form>
      </CustomModal>
    </>
  );
};

export default ChangePasswordModal;
