import axios from "axios";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../Components/Button/Button";
import ServiceBox from "../../Components/serviceBox/ServiceBox";
import "./HomeStyle.css";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { API_JOB } from "../../config";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Header from "../../Layouts/Header/Header";
import DataTable from "../../Components/dataTable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { adminJobsAction } from "../../redux/actions/jobsAction";
import List from "../../Admin/Jobs/List";
import { Popconfirm } from "antd";
import { useWindowSize } from "../../Components/useHook/useWindowSize";

const Home = () => {
  const { height, width } = useWindowSize();
  const { jobs, loading, jobLoading } = useSelector(state => state.adminJobs);
  const column = [
    {
      title: "PositionId",
      dataIndex: "positionId",
      width: "10%",
      // editable: true,
    },
    {
      title: "Location Type",
      dataIndex: "jobType",
      width: "8%",
      // editable: true,
    },
    {
      title: "Job Title",
      dataIndex: "headline",
      width: "15%",
      // editable: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "15%",
      // editable: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      width: "10%",
      // editable: true,
    },
    {
      title: "Apply Now",
      dataIndex: "",
      width: "10%",
      render: (_, record) =>
        jobs?.data?.length >= 1 ? (
          // <Link to={`/applyjob/${record.jobId}`} className="redlink">
          <a href='mailto:apply@acsincny.com' target="_top" className="redlink">
            apply@acsincny.com
            {/* <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_26_12)">
                <path
                  d="M16.3384 10.3041L7.73138 18.9111L6.31738 17.4971L14.9234 8.89014H7.33838V6.89014H18.3384V17.8901H16.3384V10.3041Z"
                  fill="#FF364C"
                />
              </g>
              <defs>
                <clipPath id="clip0_26_12">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.333984 0.890137)"
                  />
                </clipPath>
              </defs>
            </svg> */}
          </a>
        ) : null,
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllJobs = () => {
    dispatch(
      adminJobsAction({
        page: 1,
        pageSize: 10,
      })
    );
  };
  useEffect(() => {
    getAllJobs();
  }, []);

  return (
    <>
      <Header page={"home"} />
      <div className="swiperMain">
        <Swiper
          className="swiper2"
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={swiper => console.log(swiper)}
        >
          <SwiperSlide>
            <div className="sliderBox">
              <img src="./assets/img/slider1BG.jpg" alt="" />
              <div className="siderText">
                <div className="swiper-content h-100 d-flex flex-column justify-content-center align-items-start">
                  <h1 className="heroText">Clients</h1>
                  <p className="heroDetail">
                    ACS is focused on client satisfaction -we aim to fulfill our
                    client’s needs with quality and precision, while also being
                    punctual. ACS also prides itself in client confidentiality
                    and security.
                  </p>
                  <Link to="/contact">
                    <Button
                      className={"mt-30 HeroButton"}
                      name={"Get in Touch"}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderBox">
              <img src="./assets/img/slider2BG.png" alt="" />
              <div className="siderText">
                <div className="swiper-content h-100 d-flex flex-column justify-content-center align-items-start">
                  <h1 className="heroText">Partners</h1>
                  <p className="heroDetail">
                    ACS is able to provide a superior client experience to our
                    customersby collaborating with Partners. We are able to
                    provide quality at scaleand value through our network of
                    partners.
                  </p>
                  <Link to="/contact">
                    <Button
                      className={"mt-30 HeroButton"}
                      name={"Get in Touch"}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="sliderBox">
              <img src="./assets/img/slider3BG.png" alt="" />
              <div className="siderText">
                <div className=" swiper-content h-100 d-flex flex-column justify-content-center align-items-start">
                  <h1 className="heroText">Employees</h1>
                  <p className="heroDetail">
                    ACS offers a comprehensive benefits package to its
                    employees.You can be part of a close-knit team while
                    enjoying perkstypical of a large enterprise.
                  </p>
                  <Link to="/contact">
                    <Button
                      className={"mt-30 HeroButton"}
                      name={"Get in Touch"}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <section className="ServiceSection">
        <div className="container">
          <div className="topDetails">
            <h1 className="commonHeading">Our Services</h1>
            <p className="commonText">
              Scale up your business growth with highly robust.
            </p>
          </div>

          <div className="row mt-60">
            <div className="col-lg-4 col-md-6 col-sm 12 mb-3  ">
              <ServiceBox
                src={"./assets/img/code.png"}
                text={"Enterprise Technology \n Services"}
                link={"/services/ETS"}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm 12 mb-3 ">
              <ServiceBox
                src={"./assets/img/s2.png"}
                text={"Business Process \n Services"}
                link={"/services/BPS"}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm 12 mb-3 ">
              <ServiceBox
                src={"./assets/img/s3.png"}
                text={"Infrastructure Management \n Services"}
                link={"/services/Infra"}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm 12 mb-3 ">
              <ServiceBox
                src={"./assets/img/s4.png"}
                text={"Cloud Management \n Services"}
                link={"/services/Cloud"}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm 12 mb-3 ">
              <ServiceBox
                src={"./assets/img/s5.png"}
                text={"Staff Augmentation \n services"}
                link={"/services/StaffAugmentation"}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm 12 mb-3 ">
              <ServiceBox
                src={"./assets/img/s6.png"}
                text={"ERP/SAP \n Services"}
                link={"/services/ERP"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="breakerBlack">
        <div className="container">
          <h2 className="breakerHeading">
            Are you interested to work with us ?
          </h2>
          <Link to="/contact">
            <Button className={"HeroButton"} name={"Contact Us"} />
          </Link>
        </div>
      </section>

      <section className="aboutWrapper">
        <div className="container">
          <div className="row main">
            <div className="col-lg-6 ,col-md-12 col-sm-12">
              <div className="aboutLeft shadowLeft">
                <img src="./assets/img/aboutUs.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 ,col-md-12 col-sm-12 aboutRight">
              <h2 className="heading-2">About Us</h2>
              <p className="text-2 mt-15">
                When working on her Master’s thesis on a paratransit software
                syststem, Asha Ramrahkiani realized the potential of technology
                to transform people’s lives, and was inspired to found ACS in
                2011. The mission of ACS is to work with our partners in New
                York State on IT related projects and to fulfill all of their IT
                needs as effectively as possible.
              </p>
              <p className="text-2 mt-15">
                ACS strives to improve life for communities across New York
                state by modernizing government systems. ACS doesn’t just look
                to get the job done - we aim to exceed our client’s
                expectations.
              </p>
              <Link to="/contact">
                <Button className={"HeroButton mt-40"} name={"Get in Touch"} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="testimonila">
        <div className="">

          <div className="topDetails">
            <h1 className="commonHeading">Testimonials</h1>
            <p className="commonText">
            Discover why our clients keep coming back to ACS by reading their testimonials. Their kind words of <br/> appreciation inspire us to continue delivering exceptional service.
             </p>
          </div>

          <div className="swiperBox mt-50">
          
<Swiper
        slidesPerView={ width < 768 ? 1 :  3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className=" container mySwiper"
      >
   
              <SwiperSlide>
                <div className="testimonialsBox">
                  <p className="testminoal">"Buyer buzz partner network disruptive non-disclosure agreement business"</p>
                  <div className="testimonialBox mt-40">
                    <img src="./assets/img/userDemo.png" alt="" className="testimonialimg" />
                    <span className="testimonialDetail">
                      <h5 className="testiName">Albus Dumbledore</h5>
                      <span className="textTesti">Manager @ Howarts</span>
                    </span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="testimonialsBox">
                  <p className="testminoal">"Learning curve infrastructure value proposition advisor strategy user experience hypotheses investor."</p>
                  <div className="testimonialBox mt-40">
                    <img src="./assets/img/p2.png" alt="" className="testimonialimg" />
                    <span className="testimonialDetail">
                      <h5 className="testiName">Albus Dumbledore</h5>
                      <span className="textTesti">Manager @ Howarts</span>
                    </span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="testimonialsBox">
                  <p className="testminoal">"Release facebook responsive web design business model canvas seed money monetization."</p>
                  <div className="testimonialBox mt-40">
                    <img src="./assets/img/p3.png" alt="" className="testimonialimg" />
                    <span className="testimonialDetail">
                      <h5 className="testiName">Albus Dumbledore</h5>
                      <span className="textTesti">Manager @ Howarts</span>
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

        </div>
      </section> */}

      <section className="jobOpening">
        <div className="container py-5 ">
          <div className="topDetails">
            <h1 className="commonHeading">Job Openings</h1>
            <p className="commonText">
              Explore exciting career opportunities with our company. Browse our
              current job openings and join our team today!
            </p>
          </div>

          <div className="my-5">
            <List
              jobs={jobs?.data}
              column={column}
              total={jobs?.total}
              loading={jobLoading}
              noPagination
            />
          </div>

          <div className="flexBox justify-center">
            <Link to="/jobOpening">
              <Button className={"mt-30 HeroButton"} name={"View All"} />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
