import React from "react";
import DataTable from "../../Components/dataTable/DataTable";
// import { Popconfirm } from "antd";

const List = ({ jobs, loading, column, total, noPagination, actions }) => {
  return (
    <>
      <div className="data-table">
        <DataTable
          actions={actions}
          column={column}
          data={jobs}
          total={total}
          loading={loading}
          noPagination={noPagination}
        />
      </div>
    </>
  );
};

export default List;
