import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminSideMenu from "../../Components/AdminSideMenu/AdminSideMenu";
import AddJobPopup from "../../Components/Popups/AddJobPopup/AddJobPopup";
import { API_JOB } from "../../config";
import { AuthContext } from "../../contexts/AuthContext";
import AdminHeader from "../../Layouts/AdminHeader/AdminHeader";
import "./JobsStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { adminJobsAction } from "../../redux/actions/jobsAction";
import LoadingOverlay from "react-loading-overlay";
import DataTable from "../../Components/dataTable/DataTable";
import List from "./List";
import { Popconfirm } from "antd";

const Jobs = () => {
  const column = [
    {
      title: "PositionId",
      dataIndex: "positionId",
      width: "10%",
      // editable: true,
    },
    {
      title: "Location Type",
      dataIndex: "jobType",
      width: "10%",
      // editable: true,
    },
    {
      title: "Position",
      dataIndex: "headline",
      width: "15%",
      // editable: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "15%",
      // editable: true,
      
      
    },
    {
      title: "Country",
      dataIndex: "country",
      width: "10%",
      // editable: true,
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      width: "25%",
      // editable: true,
    },
    {
      title: "Posted By",
      dataIndex: "postedBy",
      width: "10%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: _ => {
        return (
          <span>
            <Popconfirm
              title="Are you sure you want to delete ?"
              onConfirm={cancel}
            >
              <button className="btn btn-danger">Delete</button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  // const column = [
  //   {
  //     title: 'Position',
  //     dataIndex: 'headline',
  //     width: '40%',
  //     editable: true,
  //   },
  //   {
  //     title: 'location',
  //     // dataIndex: `${"country" ,"city", "state" }`,
  //     dataIndex: 'location',
  //     width: '20%',
  //     editable: true,
  //   },

  //   {
  //     title: 'Action',
  //     dataIndex: 'operation',
  //     render: (_) => {
  //       return (
  //         <span>
  //           <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
  //             <a>Delete</a>
  //           </Popconfirm>
  //         </span>
  //       )
  //     },
  //   },
  // ];

  const { jobs, loading, jobLoading } = useSelector(state => state.adminJobs);
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(false);

  //   const [jobs, setJobs] = useState([]);

  //   const { user } = useContext(AuthContext);

  //   const getJobs = async () => {
  //     const res = await axios.get(API_JOB);
  //     console.log(res.data["jobs"]);
  //     setJobs(res.data["jobs"]);
  //   };
  const cancel = () => {
    console.log("ok");
  };

  const deleteJob = async id => {
    const res = await axios.delete(`${API_JOB}/${id}`, {
      headers: {
        //   Authorization: `Bearer ${user.access_token}`,
      },
    });
    console.log(res?.data);
    //   getJobs();
  };

  const getAllJobs = () => {
    dispatch(
      adminJobsAction({
        page: 1,
        pageSize: 10,
      })
    );
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  return (
    <LoadingOverlay active={loading} spinner>
      <main className="clearAll">
        <AddJobPopup isActive={isActive} onCancel={() => setIsActive(false)} />
        <AdminHeader onClick={() => setIsActive(true)} />
        <AdminSideMenu value={"jobs"} />
        <section className="blackBox jobsBox">
          <List
            jobs={jobs?.data}
            column={column}
            total={jobs?.total}
            loading={jobLoading}
            actions
            noPagination
          />
        </section>
      </main>
    </LoadingOverlay>
  );
};

export default Jobs;
