import React from "react";

import Button from "../Button/Button";
import { Modal } from "antd";
import { blue } from '@ant-design/colors';
import Loading from "../Loader/Loading";
import "./CustomModal.css"
const CustomModal = (props) => {
  const {
    centered,
    width,
    visible,
    className,
    classBtn,
    onSubmit,
    onCancel,
    loading,
    children,
    backBtnName,
    btnname,
  } = props;
   
  return (
    <>
      <Modal
      
        // title="Add Awards"
        centered
        onHide={() => {
          !loading && onCancel();
        }}
        className={`custom-modal ${className || ""}`}
       
        open={visible}
     
        // onOk={() => onSa (false)}
         onCancel={onCancel}
        footer={null}
        width={width}
      >
      {/* <Modal
        centered={centered}
        show={visible}
        // onHide={() => {
        //   !loading && onCancel();
        // }}
        // className={`custom-modal ${className || ""}`}
      > */}
        {loading && <Loading mainClass="loadingModal" />}
        {children}

        <div className="d-flex justify-content-end mt-40">
          <Button
            className={`${ classBtn ? "headerBtn" : "adminLoginBtn" }   `}
            name={backBtnName ? backBtnName : "Cancel"}
            onClick={onCancel}
          />
          <Button
            // type="submit"
            onClick={onSubmit}
            className={`HeroButton ${ classBtn ? "headerBtn" : "adminLoginBtn" }` }
            name={btnname ? btnname : "Save"}
          />
        </div>
        {/* <div className="md-footer mt-3">
        <div className="text-right mt-4">
          <LightBlueButton className='cancel-btn'
                           onClick={onCancel}></LightBlueButton>
          {onClickSubmit &&
          <GreenButton onClick={onClickSubmit} className='submit-btn'>{`$`}</GreenButton>}
        </div>
      </div> */}
      </Modal>
    </>
  );
};

export default CustomModal;
