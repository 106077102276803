// import { useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AdminLogin from "./Admin/AdminLogin/AdminLogin";
import GetInTouch from "./Admin/GetInTouch/GetInTouch";
import GovernmentExperiences from "./Admin/GovernmentExperiences/GovernmentExperiences";
import Jobs from "./Admin/Jobs/Jobs";
import JosbDetail from "./Admin/Jobs/JosbDetail";
import PartnerForm from "./Admin/PartnerForm/PartnerForm";
import TalentCommunity from "./Admin/TalentCommunity/TalentCommunity";
import "./App.css";
// import { AuthContext } from "./contexts/AuthContext";
import Footer from "./Layouts/Footer/Footer";
// import Header from "./Layouts/Header/Header";
import About from "./Pages/About/About";
import Awards from "./Pages/Awards/Awards";
import Career from "./Pages/Career/Career";
import GetJob from "./Pages/Career/GetJob";
import Partners from "./Pages/Career/Partners";
import Talent from "./Pages/Career/Talent";
import Contact from "./Pages/Contact/Contact";
import GovernmentExperience from "./Pages/GovernmentService/GovernmentExperience";
import GovernmentPlacements from "./Pages/GovernmentService/GovernmentPlacements";
import GovernmentService from "./Pages/GovernmentService/GovernmentService";
import Home from "./Pages/Home/Home";
import News from "./Pages/News/News";
import Services from "./Pages/Services/Services";
import ScrollToTop from "./ScrollToTop";
// import PageNotFound from "./Pages/PageNotFound";
import PrivateRoute from "./routes/PrivateRoute";
import { ToastContainer } from "react-toastify";
// import { userType } from "./utils";
import PublicRoute from "./routes/PublicRoute";
import PageNotFound from "./Pages/Error/PageNotFound";
// import { useToken } from "antd/es/theme/internal";
import ForgotPassword from "./Admin/ForgotPassword/ForgotPassword";
import Contracts from "./Pages/Contracts/Contract";

function App() {
  const location = useLocation();
  // const userToken = localStorage.token
  // const { user, isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <ToastContainer autoClose={4000} />
      <ScrollToTop />
      <div className="appContainer" >
        <main
          className={`${location.pathname === "/contact" ? " bg_black" : location.pathname.includes("/admin") ? "main-footer  " : ""}`}        >
          <Routes>
            <Route path="/admin" element={<PublicRoute> <AdminLogin /> </PublicRoute>} />
            <Route
              path="/admin-forgot"
              element={<ForgotPassword />}
            />
            <Route
              path="/admin-govermentExperiences"
              element={
                <PrivateRoute>
                  <GovernmentExperiences />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-partnerForm"
              element={
                <PrivateRoute>
                  <PartnerForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-talentCommunity"
              element={
                <PrivateRoute>
                  <TalentCommunity />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-jobs"
              element={
                <PrivateRoute>
                  <Jobs />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-getInTouch"
              element={
                <PrivateRoute>
                  <GetInTouch />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-jobsDetail/:id"
              element={
                <PrivateRoute>
                  <JosbDetail />
                </PrivateRoute>
              }
            />
            {/* User Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/:service" element={<Services />} />
            <Route path="/contracts/:contract" element={<Contracts />} />
            <Route
              path="/governmentCertification"
              element={<GovernmentService />}
            />
            <Route
              path="/governmentClients"
              element={<GovernmentExperience />}
            />
            <Route
              path="/governmentPlacement"
              element={<GovernmentPlacements />}
            />
            <Route path="/jobOpening" element={<Career />} />
            <Route path="/applyjob/:id" element={<GetJob />} />
            <Route path="/talentCommunity" element={<Talent />} />
            <Route path="/partner" element={<Partners />} />
            <Route path="/news" element={<News />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
        {/* {
 !userToken && <Footer />
} */}


        {location.pathname !== "/admin" &&
          location.pathname !== "/admin-govermentExperiences" &&
          location.pathname !== "/admin-partnerForm" &&
          location.pathname !== "/admin-talentCommunity" &&
          location.pathname !== "/admin-jobs" &&
          location.pathname !== "/admin-getInTouch" &&
          location.pathname !== "/admin-forgot" &&
          !location.pathname.startsWith("/admin-jobsDetail") && <Footer />}
      </div>
    </>
  );
}

export default App;
