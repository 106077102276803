import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
export const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// handle config for get/post
const handleConfig = (config) => {
  let token = localStorage.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

// handle request interceptor
axiosInstance.interceptors.request.use(
  (config) => handleConfig(config),
  (error) => Promise.reject(error)
);

// // logout on getting status 401 from any api
// const logout = () => {
//   localStorage.clear()
//   // removeAllFromLocalStorage();
//   window.location.replace('/admin');
// };

// // handle response interceptor
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       logout();
//     } else {
//       return Promise.reject(error.response);
//     }
//   }
// );

// handle response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log("error.response", error.response);
    } else {
      return Promise.reject(error.response);
    }
  }
);
