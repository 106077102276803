import React, { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import { AuthContext } from "../../contexts/AuthContext";
import "./AdminLoginStyle.css";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../Layouts/AdminHeader/AdminHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminLoginAction,
  adminLoginResetResponse,
} from "../../redux/actions/AdminLoginAction";
// import {toast}  from "react-toastify"
import { userType } from "../../utils";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay'
// import BounceLoader from 'react-spinners/BounceLoader'
const AdminLogin = () => {
  const { adminInfo, loading } = useSelector((state) => state.adminLogin);
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // const { login, isAuthenticated } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(
      AdminLoginAction({
        email: email,
        password: password,
        grantType: "password",
      })
    );
  };

  const responseHandler = () => {
    if (adminInfo.statusCode === 200) {
      toast.success("Login Successfull");
      dispatch(adminLoginResetResponse());
      if (userType() === 1) {
        navigate("/admin-jobs");
      } else {
        localStorage.clear();
        toast.warn("Access Denied");
        dispatch(adminLoginResetResponse());
      }
    } else {
      toast.warn(adminInfo.returnMessage[0]);
      dispatch(adminLoginResetResponse());
    }
  };
  useEffect(() => {
    if (adminInfo) {
      responseHandler();
    }
  }, [adminInfo]);

 const forgotHandler = () => {
  navigate("/admin-forgot")
 }
  return (
    <>
<LoadingOverlay
  active={loading}
  spinner
  >
      <AdminHeader />
      <section className="adminLoginWrap">
        <div className="adminLoginBox">
          <div className="adminHeading">Admin Login</div>
          <form onSubmit={handleLogin}>
            <Input
              className="mt-40"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <Input
              className="mt-20"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            {error && <div className="textRight redLink mt-5">{error}</div>}
            <Button
              type="submit"
              className={"HeroButton adminLoginBtn mt-40"}
              name={"Login"}
            />
             
          </form>
          <div className="text-center my-4" >
          <h6 className="text-white" onClick={()=>forgotHandler()} style={{cursor : "pointer" }}>Forgot Password</h6>
          </div>
         
        </div>
        
      <div>

      </div>
      </section>

      </LoadingOverlay>

    </>
  );
};

export default AdminLogin;
