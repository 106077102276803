import { memo, useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
  Pagination,
  Select,
} from "antd";
import { adminJobsAction } from "../../redux/actions/jobsAction";
import { useDispatch } from "react-redux";
import "./DataTable.css";
const { Option } = Select;

const DataTable = ({ column, loading, data, total, noPagination }) => {
  const pageSizeOptions = [2, 10, 20, 50, 100];
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (total) {
      setPagination({
        ...pagination,
        total: total,
      });
    }
  }, [total]);

  const paginationProps = {
    current: pagination.current,
    pageSize: pagination.pageSize,
    total: pagination.total,
    responsive: true,
    itemRender: (current, type, originalElement) => {
      if (type === "prev") {
        return (
          <span>
            <i class="fa fa-angle-double-left mx-2" aria-hidden="true"></i>left
          </span>
        );
      }
      if (type === "next") {
        return (
          <span>
            Right
            <i class="fa fa-angle-double-right mx-2" aria-hidden="true"></i>
          </span>
        );
      }
      return originalElement;
    },
    onChange: pageNumber => onPaginationSettings("current", pageNumber),
  };

  const onPaginationSettings = (key, value) => {
    setPagination(prevState => ({
      ...prevState,
      [key]: value,
    }));
    let obj = { page: pagination.current, pageSize: pagination.pageSize };
    if (key === "current") obj.page = value;
    // if (key === "pageSize"){ // COMMENT BY ME
    //   obj.pageSize = value;
    //   obj.page = 1;
    // }
    // if (key === "pageSize" && obj.pageSize >= total) { // COMMENT BY ME
    if (key === "pageSize") {
      obj.page = 1;
      obj.pageSize = value; // ADDEDD BY ME
      setPagination(prevState => ({
        ...prevState,
        page: 1,
        current: 1, // ADDEDD BY ME
      }));
    }
    data && dispatch(adminJobsAction(obj));
  };

  const renderItemsString = `${
    paginationProps.current > 1
      ? pagination.total > pagination.current * pagination.pageSize
        ? (paginationProps.current - 1) * paginationProps.pageSize + 1
        : (pagination.total > pagination.pageSize && pagination.pageSize + 1) ||
          1
      : 1
  }
        -${
          paginationProps.total >
          (paginationProps.current - 1) * paginationProps.pageSize +
            paginationProps.pageSize
            ? (paginationProps.current - 1) * paginationProps.pageSize +
              paginationProps.pageSize
            : paginationProps.total
        } items of ${paginationProps.total || ""}`;

  return (
    <Row gutter={[16, 16]} className="mt-3">
      <Col lg={24} md={24} xs={24}>
        <Table
          size="small"
          bordered
          loading={loading}
          dataSource={data}
          columns={column}
          pagination={
            noPagination
              ? false
              : {
                  current: paginationProps.current,
                  pageSize: paginationProps.pageSize,
                  total: paginationProps.total,
                }
          }
        />
        {!noPagination && (
          <div className="custom-pagination mt-2">
            <div className=" text-white">
              View{" "}
              <Select
                value={paginationProps.pageSize}
                style={{ width: 70 }}
                onChange={value =>
                  onPaginationSettings("pageSize", parseInt(value))
                }
              >
                {pageSizeOptions.map((opt, key) => (
                  <Option key={key} value={opt}>
                    {opt}
                  </Option>
                ))}
              </Select>{" "}
              Items per page
            </div>
            <div className="mr-5 text-white">{renderItemsString}</div>
            <Pagination {...paginationProps} />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default memo(DataTable);
