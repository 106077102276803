import {
  ADMIN_JOBS_REQUEST,
  ADMIN_JOBS_SUCCESS,
  ADMIN_JOBS_FAIL,
  ADMIN_JOBS_RESPONSE,
  ADMIN_ADD_JOBS_REQUEST,
  ADMIN_ADD_JOBS_SUCCESS,
  ADMIN_ADD_JOBS_FAIL,
  ADMIN_ADD_JOBS_RESPONSE,
  GET_JOB_DETAIL_BY_ID_REQUEST,
  GET_JOB_DETAIL_BY_ID_SUCCESS,
  GET_JOB_DETAIL_BY_ID_FAIL,
  APPLY_JOB_REQUEST,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_FAIL,
  APPLY_JOB_RESPONSE,
} from "../types.js";

const initialState = {
  jobs: undefined,
  addJobs: undefined,
  jobDetailById: undefined,
  applyJob: undefined,
};
export const adminJobsReducer = (state = initialState, action) => {
  switch (action.type) {

    //get job
    case ADMIN_JOBS_REQUEST:
      return { jobLoading: true };
    case ADMIN_JOBS_SUCCESS:
      return { ...state, jobLoading: false, jobs: action.payload };
    case ADMIN_JOBS_FAIL:
      return { ...state, jobLoading: false, jobs: action.payload };
    case ADMIN_JOBS_RESPONSE:
      return { ...state, jobLoading: false, jobs: undefined };
    // Add job
    case ADMIN_ADD_JOBS_REQUEST:
      return { loading: true };
    case ADMIN_ADD_JOBS_SUCCESS:
      return { ...state, loading: false, addJobs: action.payload };
    case ADMIN_ADD_JOBS_FAIL:
      return { ...state, loading: false, addJobs: action.payload };
    case ADMIN_ADD_JOBS_RESPONSE:
      return { ...state, loading: false, addJobs: undefined };
    //get job detail by id
    case GET_JOB_DETAIL_BY_ID_REQUEST :
      return {...state , loading: true };
    case GET_JOB_DETAIL_BY_ID_SUCCESS :
      return { ...state, loading: false, jobDetailById: action.payload };
    case GET_JOB_DETAIL_BY_ID_FAIL :
      return { ...state, loading: false, jobDetailById: action.payload };  
    // Apply job
    case APPLY_JOB_REQUEST:
      return { ...state, loading: true };
    case APPLY_JOB_SUCCESS:
      return { ...state, loading: false, applyJob: action.payload };
    case APPLY_JOB_FAIL:
      return { ...state, loading: false, applyJob: action.payload };
    case APPLY_JOB_RESPONSE:
      return { ...state, loading: false, applyJob: undefined }; 
    default:
      return state;
  }
};
