// Admin login
export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

//admin jobs

export const ADMIN_JOBS_REQUEST = " ADMIN_JOBS_REQUEST ";
export const ADMIN_JOBS_SUCCESS = " ADMIN_JOBS_SUCCESS ";
export const ADMIN_JOBS_FAIL = " ADMIN_JOBS_FAIL ";
export const ADMIN_JOBS_RESPONSE = " ADMIN_JOBS_RESPONSE ";
 
//admin jobs

export const ADMIN_ADD_JOBS_REQUEST = " ADMIN_ADD_JOBS_REQUEST ";
export const ADMIN_ADD_JOBS_SUCCESS = " ADMIN_ADD_JOBS_SUCCESS ";
export const ADMIN_ADD_JOBS_FAIL = " ADMIN_ADD_JOBS_FAIL ";
export const ADMIN_ADD_JOBS_RESPONSE = " ADMIN_ADD_JOBS_RESPONSE ";
 
//admin jobs

export const ADMIN_CHANGE_PASSWORS_REQUEST = "ADMIN_CHANGE_PASSWORS_REQUEST ";
export const ADMIN_CHANGE_PASSWORS_SUCCESS = "ADMIN_CHANGE_PASSWORS_SUCCESS ";
export const ADMIN_CHANGE_PASSWORS_FAIL = "ADMIN_CHANGE_PASSWORS_FAIL ";
export const ADMIN_CHANGE_PASSWORS_RESPONSE = "ADMIN_CHANGE_PASSWORS_RESPONSE ";
 
// get Detail By Id

export const GET_JOB_DETAIL_BY_ID_REQUEST = " GET_JOB_DETAIL_BY_ID_REQUEST ";
export const GET_JOB_DETAIL_BY_ID_SUCCESS = " GET_JOB_DETAIL_BY_ID_SUCCESS ";
export const GET_JOB_DETAIL_BY_ID_FAIL = " GET_JOB_DETAIL_BY_ID_FAIL ";
 
// get Apply Job

export const APPLY_JOB_REQUEST = "APPLY_JOB_REQUEST";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAIL = "APPLY_JOB_FAIL";
export const APPLY_JOB_RESPONSE = "APPLY_JOB_RESPONSE";
 
// get Upload

export const UPLOAD_CV_REQUEST = "UPLOAD_CV_REQUEST";
export const UPLOAD_CV_SUCCESS = "UPLOAD_CV_SUCCESS";
export const UPLOAD_CV_FAIL = "UPLOAD_CV_FAIL";
export const UPLOAD_CV_RESPONSE = "UPLOAD_CV_RESPONSE";
 
// forgot Password

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const FORGOT_OTP_REQUEST = "FORGOT_OTP_REQUEST";
export const FORGOT_OTP_SUCCESS = "FORGOT_OTP_SUCCESS";
export const FORGOT_OTP_FAIL = "FORGOT_OTP_FAIL";
export const CONFIRM_PASSWORD_REQUEST = "CONFIRM_PASSWORD_REQUEST";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAIL = "CONFIRM_PASSWORD_FAIL";